.cart-layout{
    height: 100%;
    max-height: 570px;
    display: flex;
    flex-direction: column;

    .cart-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        height: 74px;
        border-bottom: 1px solid;
        @include themed($Sedeo-themes) {
            color: t('text-dark');
            border-color: t('borders');
        }

        .left{
            display: flex;
            align-items: center;
            .cart-title{
                font-size: 16px;
                font-weight: 600;
                margin-left: 1rem;
            }
        }

        .right{
            .close-icon{
                @include themed($Sedeo-themes) {
                    color: t('text-dark');
                }
            }
        }
    }
    .cart-body{
        overflow: auto;
        flex: 1;
    }
    .cart-footer{

        .cart-recap-line{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 0.4rem;
            h4{
                font-size: 14px;
                font-weight: 400;
                margin: 0;
            }
            h2{
                font-size: 22px;
                margin: 0;
            }
            .loading-container{
                width: auto;
                height: auto;
                span{
                    width: 20px !important;
                    height: 20px !important;
                }
            }
        }
        hr{
            padding-top: 0;
        }
        .validate-cart-button-container{
            margin: 0.8rem 1.6rem 0.4rem;
            .validate-button{
                width: 100%;
                padding: 0.6rem;
                &:hover{
                    transform: scale(1.05);
                }
            }
        }
        .empty-cart-button-container{
            margin: 0.5rem;
            display: flex;
            justify-content: center;
            .empty-cart-button{
                text-transform: none;
            }
        }
        .invite-to-create-event{
            p{
                margin-top: 0;
            }
            >div{
                margin-top: 8px;
                display: flex;
                justify-content: center;
                button{
                    .event-icon{
                        margin-left: 1rem;
                    }
                }
            }
        }
        .empty-cart-go-to-catalog{
            font-size: 16px;
            span{
                cursor: pointer;
                font-size: 17px;
                font-weight: 600;
                svg{
                    vertical-align: middle;
                    transition: all 0.2s $FLUID;
                }
                &:hover{
                    svg{
                        transform: translateX(6px);
                    }
                }
            }
        }
        .price-details-cart-tooltip{
            .cart-price-line h4{
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                font-weight: 500;
                margin-top: 1rem;
                .loading-container{
                    width: 24px;
                    height: 24px;
                    margin: 4px 1rem;
                    span{
                        width: 100% !important;
                        height: 100% !important;
                    }
                }
                .total-amount{
                    font-size: 20px;
                    font-weight: 600;
                    margin-left: 8px;
                }
            }
        }
    }
}
.cart-item-layout{
    padding: 1rem 0.6rem;
    border-bottom: 1px solid;
    position: relative;
    @include themed($Sedeo-themes) {
        color: t('text-dark');
        border-color: t('borders');
    }

    .item-info{
        display: flex;
        flex-wrap: wrap;
    }
    .item-image{
        display: flex;
        flex: 0;
        justify-content: center;
        align-items: center;
        width: 80px;
        min-width: 80px;
        height: 80px;
        img{
            width: 80px;
            height: 80px;
            object-fit: contain;
        }
        img, svg{
            max-width: 100%;
            max-height: 100%;
        }
    }
    .item-text{
        width: 100%;
        max-width: 280px;
        min-width: 200px;
        padding: 0 0.5rem;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        h4{
            font-size: 16px;
            font-weight: 500;
            margin: 4px 0;
            line-height: 1.2;
            @include themed($Sedeo-themes) {
                color: t('text-dark');
            }
        }
        p{
            margin-top: -8px;
            margin-bottom: 8px;
            font-size: 12PX;
        }
        .loading-container{
            width: 25px;
            height: 25px;
            margin-top: -8px;
            span{
                width: 25px !important;
                height: 25px !important;
                padding: 6px;
            }
        }
    }
    .item-price{
        min-width: 120px;
        margin-left: auto;
        text-align: right;
        display: flex;
        flex-basis: 0;
        flex-direction: column;
        justify-content: flex-end;
        p{
            margin: 0;
        }
        .item-total-price{
            font-size: 18px;
            font-weight: bold;
            margin-top: -8px;
        }
    }
    .delete-button{
        display: none;
        position: absolute;
        top: 0.4rem;
        right: 1rem;
        width: 36px;
        height: 36px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    &:hover{
        .delete-button{
            display: flex;
        }
    }
    &-unavailable{
        h4{
            text-decoration: line-through;
        }
        .item-image{
            width: 80px;
            min-width: 80px;
        }
        .product-not-available-for-location-wrapper{
            margin: 0;
            svg{
                width: 24px;
                height: 24px;
                margin-right: 4px;
            }
            p{
                margin: 0;
            }
        }
        .related-categories{
            margin-bottom: 0 !important;
        }
        .quantity-container{
            margin-bottom: 4px;
        }
        .related-categories,
        .quantity-container,
        .item-price,
        .item-image,
        h4{
            opacity: 0.6;
        }
    }
}
.MuiTooltip-tooltip{
    width: 400px;
    max-width: 400px;
    background-color: transparent;
    @include themed($Sedeo-themes) {
        background-color: t('bg-default');
        color: t('medium-grey');
    }
}
.empty-cart-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    @include themed($Sedeo-themes) {
        color: t('text-dark');
    }
}
.empty-cart-info{
    margin: 2rem auto;
    text-align: center;
    max-width: 300px;
    font-size: 18px;
    @include themed($Sedeo-themes) {
        color: t('text-dark');
    }
}


@media (max-width: $SCREEN_MD) {
    .cart-item-layout{
        .delete-button{
            display: flex;
            width: 30px;
            height: 30px;
            @include themed($Sedeo-themes) {
                background-color: t('bg-light');
            }
            svg{
                width: 20px;
                height: 20px;
            }
        }
    }
}

@media (max-width: $SCREEN_SM) {

    .cart-item-layout{
        .item-text{
            max-width: 100%;
            h4{
                font-size: 14px;
            }
            .quantity-selector{
                input{
                    font-size: 10px;
                }
                svg{
                    width: 12px;
                    height: 12px;
                }
                button{
                    width: 18px;
                    height: 18px;
                }
            }
        }
        .item-image{
            width: 50px;
            min-width: 50px;
            height: 50px;
            svg{
                width: 100%;
                height: 100%;
            }
        }
        .item-price{
            min-width: 100px;
            // flex-basis: 100%;
            .item-unit-price{
                font-size: 10px;
            }
            .item-total-price{
                font-size: 14px;
            }
        }
    }
    .cart-layout{
        .cart-header{
            height: 50px;
        }
        .cart-footer{
            .cart-recap-line{
                margin-top: 0.2rem;
                h4{
                    font-size: 12px;
                }
                h2{
                    font-size: 14px;
                }
            }
            .validate-cart-button-container{
                margin: 0.8rem;
            }
        }
    }
    .invite-to-create-event{
        p{
            font-size: 12px;
            line-height: 1.4;
            margin-bottom: 8px;
        }
        button{
            font-size: 12px;
            line-height: 1.4;
        }
    }

    .MuiTooltip-tooltip{
        width: 100% !important;
        max-width: 90% !important;
        margin: auto;
    }
}