
$MAIN_PADDING: 3rem;
$MAX_WIDTH: 1400px;

.sedeo-blog-appbar-container{
    width: 100%;
    background-color: white;
    padding: 0 $MAIN_PADDING;
    max-width: $MAX_WIDTH;
    margin: auto;
    //padding: 0 5rem;
    .blog-appbar{
        width: 100%;
        height: 100px;
        display: flex;
        >div{
            flex: 1;
        }
        .middle{
            display: flex;
            align-items: center;
            justify-content: center;
            >*{
                margin: 1rem;
            }
            span{
                font-size: 32px;
                font-weight: bold;
                white-space: nowrap;
            }
            .logo{
                height: 48px;
                img{
                    width: 100%;
                    height: 100%;
                    margin-top: 4px; //just to align the logo text 'sedeo' with the page title
                }
            }
        }
    }

    .blog-categories-container{
        margin: 1rem;
        .carousel-wrapper{
            width: 100%;
            //max-width: 1200px;
            position: relative;
            margin: auto;
            .keen-slider{
                padding-right: 4rem;
            }
        }
        .blog-category-list-item{
            font-size: 16px;
            padding: 0 0.8rem;
            width: auto !important;
            //margin: 1rem;
            span{
                white-space: nowrap;
            }
            .highlighter{
                width: 100%;
                display: none;
            }
            &.selected-category{
                font-weight: bold;
                .highlighter{
                    display: block;
                }
            }
        }
    }
}

.main-blog-content{
    padding: 2rem 0;
    max-width: $MAX_WIDTH;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .blog-title{
        font-size: 32px;
        font-weight: bold;
        padding: 0 $MAIN_PADDING;
        max-width: 1100px;
        text-align: center;
        @include themed($Sedeo-themes) {
            color: t('sedeo-main-color');
        }
    }
    .blog-description{
        font-size: 18px;
        padding: 0 $MAIN_PADDING;
        max-width: 1100px;
        text-align: center;
        @include themed($Sedeo-themes) {
            color: t('text-dark');
        }
    }
}

.home-articles-container{
    margin: 3rem $MAIN_PADDING;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .blog-post-item{
        max-width: 432px;
        padding: 1rem;
        .lazy-loaded-image{
            height: auto;
            img{
                max-width: 100%;
                height: auto;
                max-height: 300px;
                object-fit: cover;
                aspect-ratio: 4 / 3;
            }
            &.error-placeholder-image{
                max-height: 300px;
                height: auto;
                display: flex;
                align-items: center;
                aspect-ratio: 4 / 3;
                svg{
                    max-height: 150px;
                }
            }
        }
        &-title{
            font-size: 18px;
            font-weight: bold;
            margin: 0.6rem 0;
            @include themed($Sedeo-themes) {
                color: t('sedeo-main-color');
            }
        }
        &-info{
            font-size: 14px;
            margin: 0.6rem 0;
            .category{
                font-weight: bold;
            }
        }
        &-description{
            font-size: 14px;
            margin: 0.6rem 0;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            p{
                display: inline;
            }
        }
    }
}

.blog-category-content{
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .category-top-banner{
        width: 100%;
        min-height: 400px;
        max-height: 500px;
        margin: auto;
        position: relative;
        aspect-ratio: 16 / 6;
        .error-placeholder-image{
            @include themed($Sedeo-themes) {
                background-color: t('bg-hover');
            }
        }
        img{
            width: 100%!important;
            height: 100%!important;
            max-width: 100%;
            max-height: fit-content;
            object-fit: cover;
            object-position: right;
        }
        .category-title{
            position: absolute;
            bottom: 10%;
            padding: 1.3rem 3rem;
            border-radius: 0 50px 50px 0;
            font-size: 18px;
            font-weight: bold;
            @include themed($Sedeo-themes) {
                background-color: t('bg-default');
                color: t('sedeo-main-color');
            }
        }
    }
    .category-description{
        margin: 2rem 5rem;
        max-width: $MAX_WIDTH;
        h1{
            font-size: 18px;
            font-weight: 500;
            text-align: center;
        }
    }
}

.blog-article-page{
    max-width: $MAX_WIDTH;
    padding: 2rem $MAIN_PADDING;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .article-top-info{
        width: 100%;
        .back-button{
            p{
                margin: 0;
                font-size: 0.83em;
                font-weight: bold;
            }
        }
        .category-title{
            margin: 0.8rem;
            font-size: 16px;
            a{
                color: unset;
            }
        }
    }
    .article-header{
        display: flex;
        .left, .right{
            flex: 1;
            padding: 1rem;
        }
        .left{
            display: flex;
            flex-direction: column;
            justify-content: center;
            h1{
                font-size: 32px;
                @include themed($Sedeo-themes) {
                    color: t('sedeo-main-color');
                }
            }
            p{
                margin: 1.4rem 0;
            }
            .keywords{
                margin: 1rem 0 0 0;
                font-size: 12px;
                font-weight: bold;
            }
            button{
                font-size: 20px;
            }
        }
        .article-image-mobile{
            display: none;
        }
        .article-image{
            .lazy-loaded-image{
                max-width: 600px;
                margin: auto;
                aspect-ratio: 4 / 3;
            }
            img{
                max-width: 100%;
                max-height: 400px;
                object-fit: cover;
            }
        }
    }
    .article-description{
        text-align: left;
        margin: 2rem 0;
        font-size: 16px;
    }

    .article-content{
        width: 100%;
        .sections-glossary{
            max-width: 700px;
            padding: 2rem 3rem;
            margin: auto;
            @include themed($Sedeo-themes) {
                background-color: t('bg-hover');
            }
            h2{
                font-size: 32px;
                line-height: 1.2;
                margin-bottom: 1.6rem;
                @include themed($Sedeo-themes) {
                    color: t('sedeo-main-color-hover');
                }
            }
            a{
                font-size: 20px;
                display: block;
                color: unset;
            }
        }
        .sections{
            .article-section{
                margin: 4rem 0;
                >h2{
                    font-size: 24px;
                    @include themed($Sedeo-themes) {
                        color: t('sedeo-main-color');
                    }
                }
                img{
                    margin: 2rem 0;
                    width: 100%!important;
                    height: 100%;
                    max-height: 525px;
                    max-width: unset;
                    object-fit: contain;
                }
                .lazy-loaded-image{
                    margin: 2rem auto;
                    max-width: 600px;
                    aspect-ratio: 4 / 3;
                    span{
                        img{
                            max-height: 525px;
                            max-width: unset;
                        }
                    }
                }
                a{
                    text-decoration: underline !important;
                }
                ul{
                    list-style: inside;
                    font-size: 16px;
                    li{
                        margin: 10px 0;
                    }
                }
                p{
                    font-size: 16px;
                    margin: 1rem 0;
                }
            }
            .section-banner{
                display: block;
                width: fit-content;
                max-width: 800px;
                max-height: 400px;
                margin: 2rem auto;
                position: relative;
                .banner-info{
                    padding: 2rem;
                    position: absolute;
                    bottom: 7%;
                    left: 0;
                    z-index: 2;
                    @include themed($Sedeo-themes) {
                        color: t('light-text');
                    }
                    h2{
                        font-size: 38px;
                        white-space: pre;
                    }
                    p{
                        font-size: 18px;
                        margin: 1rem 0;
                    }
                    .sedeo-button-rounded{
                        padding: 0.6rem 1.5rem;
                        @include themed($Sedeo-themes) {
                            color: t('sedeo-main-color');
                            background-color: t('bg-default');
                        }
                    }
                }
                .lazy-loaded-image{
                    img{
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
    }
    .article-author{
        width: 100%;
        hr{
            width: 40%;
            border-width: 0.5px;
            @include themed($Sedeo-themes) {
                border-color: t('sedeo-main-color');
            }
        }
        p{
            margin: 1rem 0;
            text-align: center;
        }
    }
    .keywords{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 2rem 0;
        p{
            margin-right: 1rem;
            font-weight: bold;
        }
        .keyword-item{
            margin: 4px;
            padding: 4px 8px;
            border-radius: 20px;
            @include themed($Sedeo-themes) {
                background-color: t('bg-hover');
            }
        }
    }
}

.newsletter-banner{
    width: 100%;
    max-width: 1000px;
    position: relative;
    @include themed($Sedeo-themes) {
        background-color: t('text-disabled');
    }
    .lazy-loaded-image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        img{
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }
    .newsletter-content{
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        display: flex;
        .left, .right{
            padding: 2rem;
        }
        .left{
            flex: 1;
            .title{
                font-size: 40px;
                line-height: 1.2;
                margin: 0;
                font-weight: bold;
                @include themed($Sedeo-themes) {
                    color: t('sedeo-main-color-hover');
                }
            }
        }
        .right{
            flex: 1.5;
            .mail-form{
                display: flex;
                align-items: flex-end;
                .email-input{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                }
                label{
                    margin-bottom: 8px;
                    font-weight: bold;
                    @include themed($Sedeo-themes) {
                        color: t('light-text');
                    }
                }
                input{
                    font-size: 18px;
                    padding: 8px;
                    background-color: rgba(255, 255, 255, 0.6);
                }
                .css-1yhbbni-MuiInputBase-root-MuiInput-root:after{
                    @include themed($Sedeo-themes) {
                        border-bottom: 2px solid t('sedeo-main-color-hover');
                    }
                }
                button{
                    min-width: 160px;
                    margin-left: 1rem;
                    padding: 9px 0;
                    text-transform: none;
                    .loading-container{
                        width: auto;
                        height: auto;
                        span{
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }
        p{
            @include themed($Sedeo-themes) {
                color: t('light-text');
            }
        }
    }
}

.blog-social-sharing{
    h4{
        font-size: 20px;
        margin: 8px;
    }
    .social-icons{
        display: flex;
        justify-content: center;
        *{
            width: 32px;
            height: 32px;
        }
        .react-share__ShareButton, .share-button{
            margin: 8px;
        }
    }
}

@media (max-width: $SCREEN_MD) {
    $MAIN_PADDING: 2rem;
    .sedeo-blog-appbar-container{
        padding: 0 $MAIN_PADDING;
        .blog-appbar{
            height: 70px;
            .middle{
                >*{
                    margin: 0.6rem;
                }
                span{
                    font-size: 28px;
                }
                .logo{
                    height: 42px;
                }
            }
        }
    }
    .main-blog-content{
        padding: 1rem 0;
        .blog-title{
            padding: 0 $MAIN_PADDING;
            font-size: 26px;
        }
        .blog-description{
            font-size: 15px;
            padding: 0 $MAIN_PADDING;
        }
    }
    .home-articles-container{
        grid-template-columns: repeat(2, 1fr);
        margin: 2rem $MAIN_PADDING;
        .blog-post-item{
            &-title{
                font-size: 16px;
            }
            &-info, &-description{
                font-size: 14px;
            }
        }
    }
    .blog-category-content{
        .category-top-banner{
            .category-title{
                font-size: 16px;
                padding: 1rem 2rem;
            }
        }
        .category-description{
            margin: 2rem $MAIN_PADDING 0;
            h1{
                font-size: 16px;
            }
        }
    }
    .blog-article-page{
        padding: 0;
        .article-top-info{
            padding: 0 $MAIN_PADDING;
        }
        .article-header{
            flex-direction: column;
            .article-image{
                img{
                    margin: 2rem 0;
                    width: 100%!important;
                    height: 100%;
                    max-height: unset;
                    max-width: 100%;
                    object-fit: cover;
                }
                &-desktop{
                    display: none;
                }
                &-mobile{
                    display: block;
                }
            }
            .left{
                padding: 1rem 0;
                h1{
                    font-size: 28px;
                    margin-bottom: 2rem;
                }
                a{
                    margin: auto;
                }
                h1, p, button{
                    padding: 0 $MAIN_PADDING;
                }
            }
        }
        .article-content{
            .sections-glossary{
                max-width: 100%;
            }
        }
        .article-description, .sections, .article-author, .keywords{
            padding: 0 $MAIN_PADDING;
        }
    }
    .newsletter-banner{
        .newsletter-content{
            .left{
                .title{
                    font-size: 28px;
                }
            }
            .right{
                .mail-form{
                    flex-direction: column;
                    align-items: flex-start;
                    .email-input, button{
                        width: 100%;
                    }
                    button{
                        margin-left: 0;
                        margin-top: 8px;
                    }
                }
            }
        }
    }
}

@media (max-width: $SCREEN_SM) {
    $MAIN_PADDING: 1rem;
    .sedeo-blog-appbar-container{
        padding: 0 $MAIN_PADDING;
        .blog-appbar{
            height: 50px;
            .middle{
                span{
                    font-size: 22px;
                }
                .logo{
                    height: 32px;
                }
            }
        }
        .blog-categories-container{
            .blog-category-list-item{
                font-size: 12px;
                padding: 0 0.4rem;
            }
        }
    }
    .main-blog-content{
        padding: 1rem 0;
        .blog-title{
            padding: 0 $MAIN_PADDING;
            font-size: 22px;
        }
        .blog-description{
            padding: 0 $MAIN_PADDING;
            font-size: 14px;
        }
    }
    .home-articles-container{
        grid-template-columns: repeat(1, 1fr);
        margin: 1rem $MAIN_PADDING;
    }
    .blog-category-content{
        padding: 1rem 0;
        .category-top-banner{
            min-height: 240px;
            .category-title{
                font-size: 14px;
                padding: 0.5rem 1rem;
            }
        }
        .category-description{
            margin: 1rem $MAIN_PADDING 0;
            h1{
                font-size: 14px;
            }
        }
    }
    .blog-article-page{
        .article-description{
            font-size: 14px;
        }
        .article-content{
            .sections-glossary{
                padding: 1rem 2rem;
                h2{
                    font-size: 24px;
                }
                a{
                    font-size: 16px;
                }
            }
            .sections{
                .article-section{
                    margin: 3rem 0;
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .newsletter-banner{
        .newsletter-content{
            flex-direction: column;
            .left{
                padding-bottom: 1rem;
            }
            .right{
                padding-top: 1rem;
            }
        }
    }
    .blog-social-sharing{
        h4{
            font-size: 16px;
        }
    }
}