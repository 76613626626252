$carouselPadding: 2rem;
.carousel-wrapper{
    width: 100%;
    position: relative;
    margin-top: 1rem;
    .shadow-mask{
        position: absolute;
        width: 80px;
        height: 100%;
        top: 0;
        z-index: 10;
        cursor: pointer;

        &-left{
            left: 0;
        }
        &-right{
            right: 0;
        }
        .arrow {
            width: 35px;
            height: 35px;
            position: absolute;
            border-radius: 50%;
            padding: 10px;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            cursor: pointer;
            z-index: 12;
            transition: all 0.2s $FLUID;
            @include themed($Sedeo-themes) {
                fill: t('text-dark');
                border: 1px solid t('lighter-grey');
            }
        }
        .arrow--left {
            left: 5px;
        }
            
        .arrow--right {
            left: auto;
            right: 5px;
        }
            
        .arrow--disabled {
            cursor: auto;
            @include themed($Sedeo-themes) {
                fill: t('text-disabled');
            }
        }

        &:hover{
            .arrow{
                padding: 8px;
                @include themed($Sedeo-themes) {
                    fill: t('medium-grey');
                }
            }
        }
    }

    .keen-slider{
        width: fit-content !important;
        max-width: 100%;
        margin: auto;
        padding-right: 1rem;
        .keen-slider__slide{
            width: fit-content !important;
            flex: none;
            border: 1px solid transparent;
        }
        .suggested-product-item{
            width: 200px !important;
        }
        &.thumbnail {
            .keen-slider__slide{
                cursor: pointer;
                height: 80px;
                >div{
                    transition: all 0.2s $FLUID;
                }
                &:hover >div{
                    transform: scale(1.1);
                }
            }
            .active{
                @include themed($Sedeo-themes) {
                    border-color: t('sedeo-main-color');
                }
                overflow: hidden;
            }
        }
    }
}
.thumbnail img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}



@media (max-width: $SCREEN_MD) {
    .carousel-wrapper{
        .keen-slider{
            .suggested-product-item{
                width: 120px !important;
                >div{
                    min-height: auto;
                }
                .lazy-loaded-image{
                    height: 80px;
                    .placeholder-image{
                        width: 100%;
                        height: 100%;
                        img{
                            width: 80% !important;
                            max-height: 80% !important;
                        }
                    }
                }
            }
        }
        .shadow-mask{
            width: 40px;
            .arrow{
                width: 20px;
                height: 20px;
                padding: 4px;
            }
            &:hover{
                .arrow{
                    padding: 3px;
                }
            }
        }
        .shadow-mask-left{
            @include themed($Sedeo-themes) {
              background-image: linear-gradient(to left,rgb(255 255 255/0), t('bg-default') 20px);
            }
        }
        .shadow-mask-right{
            @include themed($Sedeo-themes) {
                background-image: linear-gradient(to right,rgb(255 255 255/0), t('bg-default') 20px);
            }
        }
    }
}