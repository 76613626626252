.shipping-schedule-dialog{
    .MuiDialog-paper{
        width: 100%;
        max-width: 600px;
    }
}
.date-timeslot-selector-date.has-error{
    @include themed($Sedeo-themes) {
        box-shadow: 0px 0px 5px 3px t('error');
    }
}
.shipping-schedule-dialog-container{
    width: 100%;
    padding: 1rem 2rem;
    .shipping-schedule-dialog-header{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .order-stepper-container{
            width: 100%;
        }
    }
    .shipping-schedule-dialog-body{
        width: 400px;
        margin: auto;
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
        justify-content: center;
        .date-field, .schedule-field{
            padding: 0.6rem;
            .select-date-error{
                color: #E94560;
                font-weight: 600;
                margin-top: -4px;
                display: block;
            }
        }
        .schedule-field{
            button{
                margin-top: 0.6rem;
            }
        }
        .datepicker{
            padding: 0.6rem;
            padding-top: 0;
            margin: auto;
        }
        h3{
            color: #00D2B6;
            @include themed($Sedeo-themes) {
                color: t('sedeo-main-color');
            }
        }
        .date-timeslot-selector{
            height: 40px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            border-radius: 20px;
            padding: 8px 0 8px 1rem;
            font-size: 16px;
            p, button{
                line-height: 1;
                min-width: 100px;
            }
            &-date{
                width: 100%;
                padding: 0.5rem 1rem;
                border-radius: 25px;
                font-size: 16px;
                border: 1px solid gray;
            }
        }
        .date-selector-field-info{
            display: flex;
            justify-content: space-around;
            .info-error{
                color: red;
                max-width: 200px;
                line-height: 1.2;
                margin-top: 4px;
                margin-bottom: -1rem;
            }
        }
        .timeslot-selector-button{
            width: 100%;
        }
    }
    .shipping-schedule-dialog-buttons{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        button{
            padding: 0.6rem 1.2rem;
            font-size: 18px;
            font-weight: 500;
        }
        &-cancel{
            padding: 1rem 2rem;
            margin: 0 2rem;
            color: gray;
            border-radius: 50px;
        }
        &-back{
            svg{
                margin-right: 1rem;
                transition: all 0.2s $FLUID;
            }
            &:hover{
                svg{
                    transform: translateX(-8px);
                }
            }
        }
        &-next{
            margin-left: auto;
            svg{
                margin-left: 1rem;
                transition: all 0.2s $FLUID;
            }
            &:hover{
                svg{
                    transform: translateX(8px);
                }
            }
        }
        .divider{
            width: 80%;
        }
        .coefficient-info{
            padding: 1rem;
            text-align: center;
            color: #ff6b09;
            span{
                text-decoration: underline;
            }
        }
        .buttons-wrapper{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 1rem;
            .sedeo-button-text{
                    text-transform: inherit;
            }
        }
    }
}
.react-datepicker{
    border: none !important;
    .react-datepicker__month-container{
        font-size: 18px;
        .react-datepicker__header{
            background-color: transparent;
            border: none;
        }
        .react-datepicker__current-month{
            text-transform: capitalize;
            font-size: 24px;
            font-weight: 400;
        }
        .react-datepicker__day-name, .react-datepicker__current-month{
            margin: 0.5rem;
        }
        .react-datepicker__day-name{
            width: 1.8rem;
        }
        .react-datepicker__day{
            padding: 0.4rem;
            margin: 0;
            width: 45px;
            height: 42px;
            border-radius: 50%;
        }
        .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range{
            @include themed($Sedeo-themes) {
                background-color: t('bg-light');
                color: t('lighter-grey');
            }
        }
        .react-datepicker__day--selected{
            position: relative;
            @include themed($Sedeo-themes) {
                background-color: t('sedeo-main-color');
                color: t('light-text');
            }
        }
        .react-datepicker__day--range-end{
            border-radius: 0 50% 50% 0;
            @include themed($Sedeo-themes) {
                background-color: t('sedeo-main-color');
                color: t('light-text');
            }
        }
        .react-datepicker__day--outside-month{
            background-color: unset !important;
        }
    }
    .react-datepicker__navigation{
        margin: 0.3rem;
        border-radius: 50%;
        padding: 1.2rem;
        .react-datepicker__navigation-icon::before{
            width: 12px;
            height: 12px;
            @include themed($Sedeo-themes) {
                border-color: t('sedeo-main-color');
            }
        }
        &:hover{
            @include themed($Sedeo-themes) {
                background-color: t('bg-light');
            }
        }
    }
}


@media (max-width: $SCREEN_MD) {
    .shipping-schedule-dialog{
        .MuiDialog-paper{
            max-width: 500px;
        }
    }
    .shipping-schedule-dialog-container{
        height: auto;
        max-height: 90%;
        .shipping-schedule-dialog-header{
            gap: 0;
            flex-direction: column;
        }
        .shipping-schedule-dialog-body{
            flex-wrap: nowrap;
            height: auto;
            max-height: 500px;
            overflow-y: auto;
            justify-content: flex-start;
            align-items: center;
            .date-field, .schedule-field{
                width: 100%;
            }
            .datepicker{
                width: fit-content;
                padding: 0 0.6rem;
            }
        }
    }
    .react-datepicker{
        .react-datepicker__month-container{
            width: 100%;
        }
    }
}


@media (max-width: $SCREEN_SM) {
    .shipping-schedule-dialog-container{
        padding: 1rem;
        .shipping-schedule-dialog-header{
            h2{
                font-size: 14px;
            }
            .date-timeslot-selector{
                p, button{
                    font-size: 12px;
                }
            }
            .date-selector-field-info{
                font-size: 12px;
            }
        }
        .shipping-schedule-dialog-body {
            width: 100%;
            padding: 0;
            .date-field, .schedule-field{
                padding: 0;
                h3{
                    font-size: 14px;
                }
            }
            .datepicker{
                width: 100%;
                padding: 0;
                .react-datepicker{
                    width: 100%;
                    .react-datepicker__month-container{
                        width: 100%;
                        .react-datepicker__current-month{
                            font-size: 16px;
                            font-weight: 500;
                        }
                        .react-datepicker__day-name, .react-datepicker__current-month{
                            margin: 0.2rem;
                        }
                        .react-datepicker__day{
                            padding: 0.2rem;
                            width: 35px;
                            height: 35px;
                            font-size: 14px;
                        }
                    }
                    .react-datepicker__navigation{
                        padding: 0;
                        margin: 0;
                    }
                }
            }
            .schedule-field{
                .timeslot-selector-button{
                    height: 35px;
                    font-size: 14px;
                    padding: 4px 1rem;
                    line-height: 1;
                }
            }
        }
        .shipping-schedule-dialog-buttons{
            margin-top: 0.4rem;
            .coefficient-info{
                font-size: 12px;
                text-align: left;
                padding: 8px 0;
                p{
                    line-height: 1.4;
                }
            }
            .buttons-wrapper{
                margin: 0 8px;
                button{
                    font-size: 12px;
                    padding: 0.6rem;
                    line-height: 1.4;
                    svg{
                        width: 1rem;
                        height: 1rem;
                    }
                }
                .shipping-schedule-dialog-buttons{
                    &-next{
                        svg{
                            margin-left: 0.4rem;
                        }
                    }
                    &-back{
                        svg{
                            margin-right: 0.4rem;
                        }
                    }

                }
            }
        }
    }
}