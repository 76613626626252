.event-data-form-dialog-container{
    .MuiDialog-container{
        .MuiGrid-root{
            width: 100%;
        }
        .MuiPaper-root{
            border-radius: 15px;
            width: 80vw;
            max-width: 700px;
        }
        .dialog-content{
            padding: 24px;
            .event-stepper-container{
                border-radius: 20px;
                padding: 0 2rem 2rem;
                width: 100%;
                .enter-location-info{
                    text-align: center;
                    max-width: 480px;
                    margin: auto;
                }
                .order-stepper-container{
                    position: relative !important;
                    top: unset !important;
                    box-shadow: unset !important;
                    width: auto !important;
                    transition: none !important;
                    margin: auto !important;
                }
            }
            .known-venues-loading{
                .loading-container{
                    width: auto;
                    height: 60px;
                    span{
                        width: 30px !important;
                        height: 30px !important;
                    }
                }
            }
            .dialog-buttons{
                display: flex;
                justify-content: flex-end;
                margin-top: 1rem;
                button{
                    border-radius: 25px;
                    margin: 0 0.4rem;
                }
            }
        }

        .french-location-input-container{
            margin-top: 2rem;
        }

        .google-location-input-container{
            margin-top: 2rem;
        }
    }
}
.google-api-location-form-has-error{
    fieldset{
        @include themed($Sedeo-themes) {
            border-color: t('error');
        }
    }
    .error-info{
        font-size: 12px;
        margin-left: 0.8rem;
        @include themed($Sedeo-themes) {
            color: t('error');
        }
    }
}
#address-autocomplete{
    width: 100%;
}

.date-form-container{
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    >div{
        max-width: 600px;
        padding: 0.5rem 1rem;
    }
}

.location-search-line{
    padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
    .location-icon{
        margin-right: 1rem;
        @include themed($Sedeo-themes) {
            color: t('sedeo-main-color');
        }
    }
    span{
        font-weight: 400;
    }
}

#address-autocomplete-label{
    font-size: 14px;
    @include themed($Sedeo-themes) {
        color: t('text-dark') !important;
    }
}

.event-data-form-dialog-title{
    font-size: 20px;
    font-weight: 600;
    padding: 1rem 2rem 0;
    @include themed($Sedeo-themes) {
        color: t('text-dark');
    }
}

.known-venues-list-container{
    >p{
        padding: 1rem;
        text-align: center;
        font-weight: 600;
        max-width: 580px;
        margin: auto;
        @include themed($Sedeo-themes) {
            color: t('sedeo-light-orange');
        }
    }
    .venues-list{
        padding: 1rem;
        border-radius: 20px;
        max-height: 300px;
        overflow: auto;
        @include themed($Sedeo-themes) {
            border: 1px solid t('bg-light');
        }
        .no-venue-line{
            @include themed($Sedeo-themes) {
                color: t('lighter-grey');
            }
        }
        .selected-venue{
            @include themed($Sedeo-themes) {
                background-color: t('bg-light');
            }
            >span{
                font-weight: 600;
            }
            .done-icon{
                margin-left: auto;
                @include themed($Sedeo-themes) {
                    color: t('sedeo-main-color');
                }
            }
        }
    }
}



@media (max-width: $SCREEN_SM){
    .event-data-form-dialog-title{
        font-size: 12px;
    }
    .event-data-form-dialog-container{
        .MuiDialog-container{
            .dialog-content{
                padding: 1rem;
                .event-stepper-container{
                    padding: 1rem;
                }
            }
        }
    }
    #address-autocomplete{
        font-size: 10px;
    }
    .location-search-line{
        padding: 0.4rem;
        .location-icon{
            margin-right: 4px;
            width: 20px;
        }
        span{
            font-size: 10px;
        }
    }
    .MuiAutocomplete-noOptions{
        font-size: 10px !important;
    }
    $label: 10px;
    #address-autocomplete-label{
        font-size: $label;
    }
    .MuiAutocomplete-root.Mui-focused, .MuiAutocomplete-hasClearIcon{
        #address-autocomplete-label{
            top: 4px;
        }
    }
    .french-location-input-container fieldset{
        legend{
            font-size: $label;
            width: 150px !important;
        }
    }
    .google-location-input-container fieldset{
        legend{
            font-size: $label;
            width: 150px !important;
        }
    }
    .MuiPickersPopper-paper{
        transform: scale(0.8) !important;
    }
    .dialog-buttons button{
        font-size: 12px;
    }
}