.category-item{

    &-container{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        transition: all 0.2s ease-in-out;
        border-radius: 6px;
        overflow: hidden;
        width: 260px;
        max-width: 400px;
        min-width: 150px;
        height: 100%;
        @include themed($Sedeo-themes) {
            color: t('text-dark');
        }

        .sedeo-rectangle{
            left: 12%;
            opacity: 1;
            max-height: 20px;
        }

        &:hover{
            transform: scale(1.05);
            box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
        }

        &.question{
            path{
                @include themed($Sedeo-themes) {
                    fill: t('sedeo-secondary-color-lighter');
                }
            }
            .category-item-text-container{
                @include themed($Sedeo-themes) {
                    background-color: t('bg-light-brown');
                    border-radius: 20px;
                    margin: 8px;
                }
            }
        }

        .category-item-image{
            width: 80px;
            height: 80px;
            padding: 8px;
            margin: 8px auto;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 4px;
            }
    
            div, svg{
                width: 100%;
                height: 100%;
            }
            path[stroke]{
                @include themed($Sedeo-themes) {
                    stroke: t('sedeo-main-color');
                }
            }
            path[fill]{
                @include themed($Sedeo-themes) {
                    fill: t('sedeo-main-color');
                }
            }
            &:hidden{
                display: none;
            }
        }
    }
    
    
    &-text{
        padding: 0 0.8rem;
        margin: 0;
        line-height: 1.4;

        &-container{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &-title{
        width: fit-content;
        height: 63px;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.2;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
    }

    &-subtitle{
        height: 38px;
        font-size: 16px;
        font-weight: 500;
        margin: 0.4rem 0 0.2rem;
        line-height: 1;
        overflow: hidden;
    }

    &-description{
        font-size: 12px;
        margin: 0.8rem 0;
        overflow: hidden;
    }
}



@media (max-width: $SCREEN_SM) {
    .category-item-container{
        width: 100%;
        max-width: 46vw;
        .category-item{
            &-image{
                width: 50px;
                height: 50px;
            }
            &-title{
                font-size: 16px;
                height: 44px;
            }
            &-subtitle{
                font-size: 12px;
                margin: 0.2rem 0;
                height: 38px;
            }
            &-description{
                font-size: 12px;
                margin: 0 0 0.4rem 0;
            }
        }
        &.question{
            .category-item-text-container{
                padding: 8px 4px;
            }
        }
    }
}

@media (max-width: 330px) {
    .category-item-container{
        padding: 0.5rem 1rem;
        max-width: 90vw;
        .category-item-subtitle{
            height: unset;
        }
        .category-item-description{
            margin: 0.4rem 0;
            height: unset;
        }
    }
}