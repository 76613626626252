.collection-products-container{
    padding: 0 1rem;
    .products-table{
        .other-column{
            width: 130px;
        }
    }
    .product-image-name{
        display: flex;
        align-items: center;
        max-width: 100%;
        .lazy-loaded-image{
            max-width: 100px;
        }
        h3{
            margin: 0 1rem;
            font-weight: 500;
            @include themed($Sedeo-themes) {
                color: t('text-dark');
            }
        }
    }
    .quantity-container{
        margin: auto;
    }
}
.collection-label{
    margin-bottom: -8px;
    font-weight: 600;
    @include themed($Sedeo-themes) {
        color: t('sedeo-main-color');
    }
}


@media (max-width: $SCREEN_MD) {
    .collection-products-container{
        padding: 0;
        .products-table{
            th, td{
                font-size: 12px;
            }
            .product-image-name{
                .lazy-loaded-image{
                    min-width: 60px;
                }
                h3{
                    margin: 0 0.4rem;
                    font-size: 12px;
                }
            }
        }
    }
}

@media (max-width: $SCREEN_SM) {
    .collection-label{
        font-size: 12px;
        margin-bottom: -6px;
    }
    .collection-products-container{
        padding: 0;
        table{
            th,td{
                padding: 6px;
                font-size: 10px;
            }
        }
        .products-table{
            .other-column{
                width: auto;
            }
            .product-image-name{
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 80px;
                h3{
                    margin: 2px 0;
                }
            }
        }
    }
}