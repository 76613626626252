.event-modification-confirmation-dialog{
    .MuiPaper-root{
        width: 100%;
        max-width: 600px;
        .dialog-title{
            margin-top: 8px;
            font-size: 16px;
            text-align: center;
            font-weight: 700;
        }
        .dialog-content{
            text-align: center;
            padding: 0 1rem;
            p{
                line-height: 1.5;
                margin: 8px 0;
            }
        }
        .dialog-actions{
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;
        }
    }
}

.product-not-available-for-location-wrapper{
    margin: 1rem 0;
    .product-not-available-for-location-text{
        padding: 8px;
        display: flex;
        width: 100%;
        align-items: center;
        background-color: #F9EACC;
        border-radius: 6px;
        svg{
            width: 34px;
            height: 34px;
            margin-right: 8px;
        }
        p{
            text-align: left;
            margin: 0 8px;
        }
    }
    .loading-container{
        height: 80px;
    }
    .changed-items-list{
        max-height: 380px;
        overflow: auto;
        margin-top: 1rem;
        .changed-item{
            display: flex;
            align-items: center;
            background-color: #f2f2f2;
            padding: 4px;
            border-radius: 6px;
            margin: 8px 0;
            .lazy-loaded-image{
                display: flex;
                align-items: center;
            }
            .changed-item-description{
                text-align: left;
                margin-left: 8px;
                .name{
                       font-size: 16px;
                }
                .unavailable-item-name, .old-price{
                    font-size: 18px;
                    text-decoration: line-through;
                    color: gray;
                }
                .unavailable-item-notice, .new-price{
                    color: #E94560;
                }
                .new-price{
                    font-size: 20px;
                    margin: -8px 4px 0 0;
                    display: inline-block;
                }
            }
        }
    }

}


@media (max-width: $SCREEN_SM) {
    .event-modification-confirmation-dialog{
        .MuiPaper-root{
            margin: 1rem;
        }
    }
    .product-not-available-for-location-wrapper{
        .product-not-available-for-location-text{
            flex-direction: column;
            svg{
                margin: 0;
            }
            p{
                font-size: 12px;
            }
        }
        .loading-container{
            height: 60px;
        }
        .changed-items-list{
            .changed-item{
                .lazy-loaded-image{
                    width: 60px;
                    height: 60px;
                    svg, img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .changed-item-description{
                    .name{
                        font-size: 12px;
                    }
                    .unavailable-item-name, .old-price{
                        font-size: 12px;
                    }
                    .unavailable-item-notice{
                        font-size: 12px;
                    }
                    .new-price{
                        font-size: 16px;
                    }
                }
            }
        }
    
    }
}