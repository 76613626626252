.grid-card{
    display: flex;
    .sedeo-card{
        width: 100%;
    }
}
.cagnotte-card-title{
    width: 100%;
    font-size: 22px;
    font-weight: 500;
}
.cagnotte-header-agenda{
    height: 240px;
    @include themed($Sedeo-themes) {
        background-color: t('bg-light');
    }
    h2{
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 8px;
    }
    p{
        font-size: 16px;
    }
}
.cagnotte-header-card{
    .cagnotte-amount-container{
        padding: 1rem 8px 0;
        font-size: 32px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include themed($Sedeo-themes) {
            color: t('sedeo-main-color');
        }
        .bars-animation{
            transform: rotate(-90deg);
            margin-top: -7px;
        }
    }
    .cagnotte-card-amount{
        display: inline-block;
        transition: all 0.2s $FLUID;
        margin-right: 8px;
        transform-origin: bottom left;
        span{
            white-space: nowrap;
        }
    }
    &:hover{
        .cagnotte-card-amount{
            transform: scale(1.1);
        }
    }
}
.cagnotte-header-month{
    @include themed($Sedeo-themes) {
        background-color: t('sedeo-main-color');
    }
    padding: 0;
    position: relative;
    height: 240px;
    .cagnotte-bg{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    &-content{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        p{
            @include themed($Sedeo-themes) {
                color: t('light-text');
            }
            font-size: 48px;
            font-weight: 600;
            transform-origin: bottom center !important;
        }
        .evolution{
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: 500;
            @include themed($Sedeo-themes) {
                color: t('light-text');
            }
            .arrow{
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include themed($Sedeo-themes) {
                    background-color: t('bg-light');
                    color: t('text-dark');
                }
            }
        }
        .cagnotte-card-amount{
            display: inline-block;
            transition: all 0.2s $FLUID;
            margin-right: 8px;
            transform-origin: bottom left;
        }
        &:hover{
            .cagnotte-card-amount{
                transform: scale(1.1);
            }
        }
        .cagnotte-month-amount-container{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2rem 0;
            
        }
    }
    .cagnotte-header-month-content-amount{
        height: 100%;
        display: flex;
        align-items: center;
    }
}
.cagnotte-mini-card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cagnotte-header-info{
    @include themed($Sedeo-themes) {
        background-color: t('bg-light');
    }
    text-align: center;
    justify-content: center;
    .underlined{
        text-decoration: underline;
    }
}
.cagnotte-tabs-container{
    width: 100%;
    margin: 1rem;
    .cagnotte-tabs-header{
        min-height: 40px;
        >div{
            overflow-x: auto !important;
        }
        button{
            padding: 0 12px;
            text-transform: none;
            font-size: 16px;
            font-weight: 500;
            min-height: 40px;
            letter-spacing: inherit;
            &.Mui-selected{
                @include themed($Sedeo-themes) {
                    color: t('text-dark');
                }
            }
            &:hover{
                @include themed($Sedeo-themes) {
                    background-color: t('bg-light');
                }
            }
        }
        .MuiTabs-indicator{
            height: 3px;
        }
    }
    .cagnotte-tabpanel{
        .sedeo-card{
            margin: 2rem 0;
        }
        .sedeo-table{
            width: 100%;
            overflow-x: auto;
            .sedeo-table-row{
                font-size: 16px;
                padding: 8px;
                td:last-child{
                    display: flex;
                    width: fit-content !important;
                }
            }
            td, th{
                min-width: 80px;
            }
            .last-cell{
                .order-status-chip{
                    min-width: fit-content;
                }
            }
        }
    }
}
.cagnotte-tab-invitations{
    display: flex;
    align-items: center;
    gap: 2rem;
    .sedeo-card{
        width: 100%;
    }
    .invitation-code-container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
        padding: 2rem 1rem;
        .invitation-code-wrapper{
            min-width: 300px;
            padding: 1rem 2rem;
            display: flex;
            justify-content: center;
            border-radius: 20px;
            cursor: pointer;
            @include themed($Sedeo-themes) {
                background-color: t('bg-light');
            }
            &:hover{
                @include themed($Sedeo-themes) {
                    background-color: t('sedeo-main-color-light');
                }
            }
        }
    }
    p{
        margin: auto;
        text-align: center;
        max-width: 400px;
    }
}
.cagnotte-order-page{
    width: 100%;
    .order-card-title{
        font-weight: 400;
    }
}

.cagnotte-activation-wrapper{
    padding: 1rem;
}
.cagnotte-activation-container{
    width: auto;
    margin: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: #f0f0f0;
    border-radius: 20px;
    h2{
        font-size: 28px;
    }
    h4{
        font-size: 18px;
    }
    p{
        width: 100%;
        max-width: 800px;
        font-size: 18px;
    }
    button{
        font-size: 18px;
    }
    .piggy-animation{
        height: 230px;
        margin-top: -5rem;
        margin-bottom: -20px;
    }
}
.venue-dialog{
    .venue-dialog-body{
        padding: 0 2rem;
    }
    .dialog-actions{
        button{
            text-transform: none;
        }
    }
}


@media (max-width: $SCREEN_LG) {
    .cagnotte-card-title{
        font-size: 18px;
        font-weight: 500;
    }
    .cagnotte-card-amount{
        font-size: 30px !important;
    }
    .cagnotte-header-month{
        height: 160px;
    }
    .cagnotte-mini-card{
        height: 160px;
    }
    .cagnotte-tab-invitations{
        gap: 1rem;
        .invitation-code-container{
            padding: 2rem 1rem 1rem;
            .invitation-code-wrapper{
                min-width: 200px;
            }
        }
    }
}

@media (max-width: $SCREEN_MD) {
    .cagnotte-card-title{
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 8px;
    }
    .cagnotte-header-agenda{
        height: fit-content;
        p{
            font-size: 14px;
            line-height: 1.6;
        }
    }
    .cagnotte-header-card{
        .cagnotte-amount-container{
            padding: 0 8px;
            font-size: 18px;
            .bars-animation{
                margin-top: -7px;
            }
        }
    }
    .cagnotte-card-amount{
        margin-right: 8px;
        font-size: 20px !important;
    }
    .cagnotte-header-month{
        height: 145px;
        &-content{
            .cagnotte-card-amount{
                font-size: 28px;
            }
            .evolution{
                font-size: 16px;
                .arrow{
                    width: 25px;
                    height: 25px;
                    .arrow-up-animation{
                        height: 20px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
    .cagnotte-mini-card{
        height: 145px;
    }
    .cagnotte-tabs-container{
        .cagnotte-tabs-header{
            button{
                padding: 0 8px;
                font-size: 14px;
            }
        }
        .cagnotte-tabpanel{
            .sedeo-table{
                .sedeo-table-row{
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width: $SCREEN_SM) {
    .cagnotte-header-agenda h2{
        font-size: 22px;
    }
    .cagnotte-card-title{
        font-size: 14px;
    }
    .sedeo-card{
        padding: 8px;
        margin: 8px;
    }
    .cagnotte-header-month-content{
        padding: 8px;
        .evolution{
            font-size: 14px;
            .arrow{
                width: 18px;
                height: 18px;
            }
        }
    }
    .cagnotte-header-card{
        .cagnotte-amount-container{
            flex-direction: column;
            height: -webkit-fill-available;
            .cagnotte-card-amount{
                height: -webkit-fill-available;
                display: flex;
                align-items: center;
            }
            .bars-animation{
                height: 20px !important;
                margin-top: -4px;
                margin-left: auto;
            }
        }
    }
    .cagnotte-card-amount{
        font-size: 16px !important;
    }
    .cagnotte-tab-invitations{
        flex-direction: column;
        gap: 0;
        .invitation-code-container{
            padding: 1rem;
        }
        p{
            font-size: 12px;
            line-height: 1.6;
        }
    }
    .cagnotte-header-info{
        font-size: 12px;
    }
    .cagnotte-tabs-container{
        margin: 1rem 0;
        .cagnotte-tabpanel{
            .sedeo-table{
                .sedeo-table-row{
                    font-size: 12px;
                }
            }
        }
    }
}