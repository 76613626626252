.quantity-container{
    width: fit-content;
    .quantity-selector{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        button{
            width: 22px;
            height: 22px;
            border-radius: 50%;
            padding: 6px;
            @include themed($Sedeo-themes) {
                color: t('bg-default');
                background-color: t('sedeo-main-color');
            }
        }

        button[disabled]{
            @include themed($Sedeo-themes) {
                background-color: t('text-disabled');
            }
        }
    
        &-input{
            flex: 1;
            display: flex;
            justify-content: center;
            width: 60px;
            min-width: 60px;
            margin: 0 4px;
            text-align: center;
            text-decoration: none;
            padding: 4px;
            @include themed($Sedeo-themes) {
                color: t('bg-default') !important;
            }
            input{
                text-align: center;
                padding: 3px;
            }
        }
    }
}


@media (max-width: $SCREEN_SM) {
    .quantity-container{
        .quantity-selector{
            button{
                width: 22px;
                height: 22px;
                margin: 4px !important;
            }
            .quantity-selector-input{
                margin: 0;
            }
            &-input{
                width: 60px;
                input{
                    padding: 3px;
                }
            }
        }
    }
}