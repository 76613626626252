.breadcrumb-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0.4rem;
    margin-bottom: 1rem;
    p{
        margin: 0;
    }
    .breadcrumb-item{
        transition: all 0.5s $FLUID;
        border-radius: 25px;
        padding: 0.2rem 0.4rem;
        font-size: 14px;
        &.bold{
            font-weight: bold;
        }
        &.clickable{

            &:hover{
                padding: 0.2rem 0.8rem;
                @include themed($Sedeo-themes) {
                    color: t('medium-grey');
                    background-color: t('bg-hover');
                }
            }
        }
        p{
            line-height: 0;
        }
    }
    .bc-return-home{
        margin: 0;
        text-align: center;
        //width: 70px;
        transition: all 0.5s $FLUID;
        @include themed($Sedeo-themes) {
            color: t('text-dark');
        }
        .arrow-back{
            margin-right: 0.4rem;
            font-size: 20px;
            transition: all 0.5s $FLUID;
        }
        &:hover{
            //width: 80px;
            .arrow-back{
                transform: scale(1.2);
            }
        }
    }
}


@media (max-width: $SCREEN_MD) {
    .breadcrumb-container{
        padding: 0.2rem 0;
        margin-top: 0;
        margin-bottom: 0;
        .breadcrumb-item{
            font-size: 10px;
            padding: 0.2rem;
        }
        .bc-return-home{
            width: 50px;
            &:hover{
                width: 60px;
            }
        }
        svg{
            width: 12px;
            height: 12px;
        }
    }
}


// @media (max-width: $SCREEN_SM) {
//     .breadcrumb-container{
//         padding: 0.6rem 0;
//         .breadcrumb-item{
//             font-size: 14px;
//             padding: 0.2rem;
//         }
//         .bc-return-home{
//             width: 80px;
//             &:hover{
//                 width: 90px;
//             }
//         }
//     }
// }