.landing-banner-content {
  padding: 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.landing-banner{
  width: 100%;
  min-height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  .overlay{
    display: none;
  }
  .lazy-loaded-image{
    min-height: 300px;
    position: absolute;
    top: 0;
    left: 0;
      img{
        min-height: 300px;
        width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: top right;
      }
  }
  .landing-content{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    .left, .right{
      padding: 2rem;
    }
    .left{
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-left: 4rem;
      .title{
        font-size: 40px;
        line-height: 1.2;
        margin: 0;
        font-weight: bold;
        @include themed($Sedeo-themes) {
          color: t('light-text');
        }
        &.christmas{
          color: #FE0000;
        }
      }
      .subtitle{
        font-size: 24px;
        line-height: 1.2;
        margin: 0;
        font-weight: bold;
        @include themed($Sedeo-themes) {
          color: t('sedeo-main-color-hover');
        }
        &.christmas{
          color: #305E3D;
        }
      }
      p{
        white-space: break-spaces;
        &.christmas{
          color: #305E3D;
        }
      }
    }
    p{
      @include themed($Sedeo-themes) {
        color: t('light-text');
      }
    }
  }
}


.landing-section-cta {
  .main-grid {
    flex-direction: row;
    justify-content: center;
    margin: 2rem 4rem;
    padding: 0 1rem;
    max-width: 1024px;
    transition: all 200ms ease;

    .content-grid {
      flex-direction: column;
      padding: 0 2rem;
      grid-gap: 2rem;
      align-items: center;
      text-align: center;

      .sec1-description {
        line-height: 1.43;
        font-size: 25px;
        font-weight: 200;
        margin: 1rem 0;
        @include themed($Sedeo-themes) {
          color: t('text-dark');
        }

        > span {
          font-weight: bold;
          position: relative;
          overflow: hidden;
        }
      }

      &:hover {
        .sedeo-rectangle {
          left: 10%;
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: $SCREEN_LG) {
  $MAIN_PADDING: 2rem;
  .landing-banner{
    .landing-content{
      .left{
        gap: 12px;
        max-width: 500px;
        .title{
          font-size: 28px;
          &.christmas{
            color: #FE0000;
          }
        }
        .subtitle{
          font-size: 20px;
          line-height: 1.2;
          margin: 0;
          font-weight: bold;
          @include themed($Sedeo-themes) {
            color: t('sedeo-main-color-hover');
          }
          &.christmas{
            color: #305E3D;
          }
        }
      }

    }
  }
}

@media (max-width: $SCREEN_MD) {
  $MAIN_PADDING: 2rem;
  .landing-banner{
    .overlay{
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(0,0,0,0.5) 45%, rgba(0,0,0,0) 70%);
    }
    .landing-content{
      .left{
        max-width: 470px;
        gap: 12px;
        .title{
          font-size: 28px;
          &.christmas{
            color: #FE0000;
          }
        }
        .subtitle{
          &.christmas{
            color: #FFFFFF;
          }
        }
        p{
          &.christmas{
            color: #FFFFFF;
          }
        }
      }
    }
  }
  .landing-section-cta{
    .main-grid{
      margin: 2rem 1rem;
      background-color: transparent !important;
      padding: 0;
    }
  }
}

@media (max-width: $SCREEN_SM) {
  $MAIN_PADDING: 1rem;
  .landing-banner{
    .overlay{
      background: linear-gradient(90deg, rgba(0,0,0,0.5) 45%, rgba(0,0,0,0) 100%);
    }
    .landing-content{
      flex-direction: column;
      .left{
        max-width: 350px;
        padding-bottom: 1rem;
        gap: 0;
        padding-left: 2rem;
      }
    }
  }
  .landing-section-cta{
    .main-grid{
      .content-grid{
        grid-gap: 1rem;
        padding: 0;
      }
    }
  }
}