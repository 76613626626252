
// Screen breakpoints
$SCREEN_XS: 380px;
$SCREEN_SM: 600px;
$SCREEN_MD: 900px;
$SCREEN_LG: 1200px;
$SCREEN_XL: 1536px;


$HOME_VIEWPORT_MAX_WIDTH: 1200px;
$MAIN_APP_BORDERS_MARGIN: 5rem;
$HOME_SPACE_BETWEEN_SECTIONS: 2rem;

$main-title-size: 50px;
$appbar-min-height: 80px;

$FLUID: cubic-bezier(.42,.42,0,1);