.sedeo-button-normal{
    @include themed($Sedeo-themes) {
        background-color: t('sedeo-main-color');
        color: t('light-text');
    }

    &:hover, &:active{
        @include themed($Sedeo-themes) {
            background-color: t('sedeo-main-color-hover');
        }
        box-shadow: none;
    }
}

.sedeo-button-rounded{
    // I marked all of these attributes as important cause the material UI generates styles when rendering the page that may affect the first contentful paint and especially cause a cumulative layout shift
    border-radius: 50px !important;
    border: none !important;
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0) !important;
    transition: all 200ms ease !important;
    text-transform: none !important;
    @include themed($Sedeo-themes) {
        background-color: t('sedeo-main-color') !important;
        color: t('light-text') !important;
    }

    &:hover, &:active{
        @include themed($Sedeo-themes) {
            background-color: t('sedeo-main-color-hover');
        }
        box-shadow: none;
    }
    &:focus{
        outline: none,
    }
}

button:disabled{
    cursor: no-drop;
    border: none !important;
    @include themed($Sedeo-themes) {
        background-color: t('in-process') !important;
        color: t('lighter-grey') !important;
    }
    &.text-button{
        @include themed($Sedeo-themes) {
            background-color: unset !important;
            color: t('lighter-grey') !important;
        }
    }
}

.sedeo-button-text{
    font-weight: 500;
    border-radius: 20px;
    padding: 8px 12px;
}

.delete-button{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    @include themed($Sedeo-themes) {
        color: t('error');
    }
}

.next-button{
    min-width: 90px;
}

.back-button{
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 15px;
    background-color: transparent;
    transition: all 0.2s $FLUID;
    svg{
        width: 20px;
        height: 20px;
        transform: translateX(0);
        transition: all 0.2s $FLUID;
        @include themed($Sedeo-themes) {
            fill: t('sedeo-main-color');
        }
    }
    &:hover{
        @include themed($Sedeo-themes) {
            background-color: t('bg-hover');
        }
        svg{
            transform: translateX(-4px);
        }
    }
}

.continue-to-transport-button{
    padding: 1rem;
    margin: 8px 0;
}

.price-loading-button .loading-container{
    width: auto;
    height: 24px;
    span{
        width: 30px;
        height: 30px;
    }
}

.timeslot-selector-button{
    font-size: 16px;
    font-weight: 500;
    min-height: 20px;
    max-height: 45px;
    height: 45px;
    min-width: 120px;
    border-radius: 25px;
    padding: 8px 1rem;
    text-align: center;
    line-height: 1.4;
    text-transform: none;
    transition: none;
    @include themed($Sedeo-themes) {
        background-color: t('bg-default');
        border: 1px solid t('lighter-grey');
    }
    &:hover{
        @include themed($Sedeo-themes) {
            background-color: t('bg-hover');
        }
    }
    .dropdown-arrow-icon{
        margin-left: auto;
        &.inversed{
            transform: rotate(180deg);
        }
    }
    &.has-error{
        @include themed($Sedeo-themes) {
            box-shadow: 0px 0px 5px 3px t('error');
        }
    }
}

.shipping-schedule-button{
    border-radius: 20px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    padding: 8px;
    background-color: transparent;
    transition: all 0.5s $FLUID;
    cursor: pointer;
    @include themed($Sedeo-themes) {
        border: 1px solid t('lighter-grey');
    }
    &:hover{
        @include themed($Sedeo-themes) {
            background-color: t('bg-light');
        }
    }
    .placeholder{
        @include themed($Sedeo-themes) {
            color: t('lighter-grey');
        }
    }
    p{
        line-height: 1;
    }
}

button{
    .loading-container{
        max-width: 24px;
        max-height: 24px;
        >span{
            max-width: 24px;
            max-height: 24px;
        }
    }
}