.order-modification-page{
    .right-panel{
        .continue-to-transport-button{
            .loading-container{
                width: 24px;
                height: 24px;
                span{
                    width: 24px !important;
                    height: 24px !important;
                }
            }
        }
    }
}
.order-modification-limit-reached{
    margin: 2rem 0;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    p{
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 2rem;
    }
}

.add-other-items-button{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem;
    button{
        padding: 1rem;
    }
}

.order-modification-add-items-dialog{
    .order-modification-dialog-box{
        padding: 24px;
        .event-data-form-dialog-title{
            padding: 0 0 2rem 0;
        }
        .results-label{
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin: 1rem 0;
        }
        .search-results-container{
            max-height: 600px;
            overflow: auto;
            .lazy-loaded-image{
                max-width: 50px;
                max-height: 50px;
                margin-right: 8px;
                img{
                    max-width: 50px;
                    max-height: 50px;
                    object-fit: cover;
                }
            }

            .error-loading-image-container{
                max-width: 120px;
            }
        }
    }
}


@media (max-width: $SCREEN_SM) {
    .order-modification-add-items-dialog{
        .order-modification-dialog-box{
            padding: 12px;
            width: 100%;
            margin: 1rem;
            .results-label{
                font-size: 12px;
                margin: 8px 0;
            }

        }
    }
}