.account-page{
    padding: 0 2rem;
}
.menu-profile-loading-container{
    margin: 1rem;
    padding: 1rem;
    width: 90%;
    border-radius: 8px;
    @include themed($Sedeo-themes) {
        background-color: t('bg-light');
    }
    .loading-container{
        width: 40px;
        height: 40px;
        margin: 0;
    }
}
.profile-side-info{
    margin: 1rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 8px;
    width: 90%;
    transition: width 0.2s $FLUID;
    position: relative;
    @include themed($Sedeo-themes) {
        background-color: t('bg-light');
    }
    .profile-img{
        width: 40px;
        height: 40px;
        padding: 10px;
        border-radius: 50%;
        background-color: white;
        svg{
            width: 100%;
            height: 100%;
            path{
                @include themed($Sedeo-themes) {
                    fill: t('sedeo-main-color');
                }
            }
        }
    }
    .profile-info{
        margin-left: 8px;
        h2{
            font-size: 16px;
        }
        p{
            font-size: 10px;
            margin-top: -4px;
        }
    }
    .chevron-right{
        position: absolute;
        right: 10px;
        opacity: 0;
        transition: opacity 0.4s $FLUID;
    }
    &:hover{
        width: 96%;
        .chevron-right{
            opacity: 1;
        }
    }
}

.profile-side-menu-container{
    position: sticky;
}

.profile-side-menu{
    margin: 1rem;
    padding: 8px;
    display: flex;
    flex-direction: column;
    hr{
        width: 90%;
        height: 1px;
        margin-top: 1rem;
        opacity: 0.6;
    }
    button{
        margin: 8px;
        border-radius: 20px;
        width: fit-content;
    }

    .account-menu-item{
        border-radius: 8px;
        margin: 4px 0;
        a{
            display: flex;
            padding: 10px 8px;
            .menuIcon{
                width: 24px;
                height: 24px;    
            }
            svg{
                width: 24px;
                height: 24px;
                @include themed($Sedeo-themes) {
                    color: t('sedeo-main-color');
                }
            }
            h3{
                margin: 0 8px;
                font-size: 14px;
            }
        }
        &.selected{
            @include themed($Sedeo-themes) {
                background-color: t('bg-light');
            }
        }
    }
}

.account-menu-mobile{
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    margin-left: -2rem;
    padding: 0 0.6rem;
    justify-content: space-around;
    box-shadow: 0px -3px 5px 0px #e2e2e2;
    z-index: 1000;
    @include themed($Sedeo-themes) {
        background-color: t('bg-default');
    }
    &-item{
        flex: 1;
        padding: 0.6rem;
        a{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .icon{
                width: 34px;
                height: 34px;
                padding: 6px;
                border-radius: 50%;
                svg{
                    width: 100%;
                    height: 100%;
                    @include themed($Sedeo-themes) {
                        color: t('sedeo-main-color');
                    }
                }
            }
            span{
                font-size: 10px;
                font-weight: 500;
            }
        }
        &.selected{
            @include themed($Sedeo-themes) {
                background-color: t('bg-light');
            }
            .icon{
                transform: scale(1.3);
            }
            span{
                font-weight: 700;
            }
        }
    }
}

@media (max-width: $SCREEN_SM) {
    .account-page{
        padding: 0 8px;
    }
    .orders-row-mobile{
        .orders-row-mobile-line{
            h2{
                font-size: 12px;
            }
            p{
                font-size: 10px;
            }
        }
        .order-status-chip{
            span{
                font-size: 10px;
            }
        }
        button{
            font-size: 10px;
        }
    }
    .account-menu-mobile{
        margin-left: -8px;
    }
}