.contact-info{
    margin: 2rem;
    text-align: center;
    font-size: 18px;
    .social-icons{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 1rem 0;
        a{
            display: flex;
            align-items: center;
        }
        .social-icon{
            width: 30px;
            height: 30px;
            cursor: pointer;
            svg {
                width: 100%;
                height: 100%;
                transform: scale(1);
                transition: all 0.2s $FLUID;
            }
            &:hover{
                svg{
                    transform: scale(1.2);
                }
            }
            &.facebook{
                width: 28px;
                height: 28px;
            }
        }
    }
}

.footer-container{
    padding: 2rem 0;
    margin-top: 6rem;
    @include themed($Sedeo-themes) {
        background-color: t('sedeo-main-color');
    }

    .footer-content{
        display: flex;
        justify-content: center;
        // max-width: $HOME_VIEWPORT_MAX_WIDTH;
        width: 90%;
        margin: auto;

        &-logo{
            text-decoration: none;
            width: fit-content;
            img{
                width: 200px;
                height: auto;
                margin-bottom: 1rem;
            }
        }

        .address-line{
            font-size: 18px;
            margin: 0;
            @include themed($Sedeo-themes) {
                color: t('light-text');
            }
        }

        .left{
            display: flex;
            flex: 0.8;
            flex-direction: column;
        }

        .links-container{
            display: flex;
            flex: 0.4;
            justify-content: flex-end;
            align-items: flex-start;
        }

        .footer-link-title{
            font-size: 20px;
            font-weight: bold;
            margin: 0 0 2rem 0;
            @include themed($Sedeo-themes) {
                color: t('light-text');
            }
        }

        .footer-link{
            display: block;
            margin: 1rem 0;
            position: relative;
            width: fit-content;
            overflow-x: auto;
            overflow-y: hidden;
            .sedeo-rectangle{
                height: 60% !important;
                bottom: -2px;
                width: 80%;
            }
            a{
                font-size: 18px;
                font-weight: 400;
                text-decoration: none;
                cursor: pointer;
                z-index: 1;
                @include themed($Sedeo-themes) {
                    color: t('light-text');
                }
            }
            &:hover{
                .sedeo-rectangle{
                    left: 10%;
                    opacity: 1;
                }
            }
        }
    }

    .social-container{
        flex: 0.4 1;
        display: flex;
        justify-content: flex-end;
        .all-rights-reserved{
            font-size: 14px;
            margin: 2rem 0 8px;
            font-weight: 400;
            @include themed($Sedeo-themes) {
                color: t('light-text');
            }
        }
        .rating{
            display: flex;
            align-items: center;
            gap: 8px;
            @include themed($Sedeo-themes) {
                color: t('light-text');
            }
            span{
                font-size: 18px;
                font-weight: 500;
            }
            svg{
                @include themed($Sedeo-themes) {
                    fill: t('light-text');
                }
            }
        }
        .social-icons{
            display: flex;
            gap: 8px;
            align-items: center;
            .social-icon{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                &:hover{
                    background-color: white;
                }
                path{
                    transition: all 0.2s $FLUID;
                }
                div, svg{
                    width: 100%;
                    height: 100%;
                }
                &.email:hover path{
                    @include themed($Sedeo-themes) {
                        fill: t('text-dark');
                    }
                }
                &.linkedin:hover path{
                    fill: #0A66C2;
                }
                &.instagram {
                    path{
                        fill: white;
                    }
                    &:hover{
                        path{
                            fill: revert-layer;
                        }
                    }
                }
                &.pinterest:hover path{
                    fill: #BB0F23;
                }
                &.facebook:hover path{
                    fill: #4460A0;
                }
                &.youtube:hover path{
                    fill: #CE1312;
                }
            }
        }
    }
}

@media (max-width: $SCREEN_LG) {
    .footer-container{
        .footer-content{
            .left{
                flex: 0.5 1;
            }
            .social-container{
                flex: 0.2 1;
            }
        }
    }
}

@media (max-width: $SCREEN_MD) {
    .footer-container{
        padding: 3rem 1rem;
        margin-top: 4rem;
        .footer-content{
            flex-wrap: wrap;
            row-gap: 1rem;
            justify-content: space-between;
            .social-container{
                flex: auto;
                width: 100%;
                justify-content: flex-start;
                .all-rights-reserved{
                    margin: 1rem 0 8px;
                }
            }
            &-logo{
                img{
                    width: 160px;
                }
            }
            .address-line{
                font-size: 12px;
            }
            .footer-link-title{
                font-size: 16px;
                margin-bottom: 1rem;
            }
            .footer-link{
                margin: 4px;
                a{
                    font-size: 12px;
                }
            }
        }

        .all-rights-reserved{
            font-size: 14px;
        }
    }
}

@media (max-width: $SCREEN_SM) {
    .footer-container{
        padding: 3rem 1rem;
        .footer-content{
            flex-direction: column;
            align-items: center;
            .left{
                align-items: center;
            }
            .links-container{
                .quick-links{
                    text-align: center;
                    margin-top: 2rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
            &-logo{
                img{
                    margin-bottom: 0;
                }
            }
        }

        .all-rights-reserved{
            text-align: center;
        }
    }
    .contact-info{
        font-size: 14px;
        margin: 1rem;
        .social-icons{
            .social-icon{
                width: 30px;
                height: 30px;
                &.facebook{
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}

