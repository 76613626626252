.topbar-categories{
    width: 100%;
    display: inline-block;
    z-index: 200;
    @include themed($Sedeo-themes) {
        background-color: t('bg-default');
    }
}
.topbar-categories-container{
    display: flex;
    align-items: center;
    height: 100px;
    position: relative;
    .carousel-wrapper{
        margin-top: 0rem;
        .keen-slider__slide{
            min-width: 100px;
            a{
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
    .loading-container{
        height: 110px;
    }
    &.is-in-home{
        height: 70px;
    }
}
.topbar-category-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    border-radius: 10px;
    position: relative;
    width: fit-content;
    &-tooltip .MuiTooltip-tooltip{
        background-color: transparent;
    }
    &-left{
        .topbar-category-item-image{
            width: 35px;
            height: 35px;
            transition: all 0.3s $FLUID;
            display: flex;
            justify-content: center;
            align-items: center;
            *{
                max-width: 35px;
                max-height: 35px;
            }
        }
    }
    &-right{
        padding: 4px;
        text-align: center;
        @include themed($Sedeo-themes) {
            color: t('text-dark');
        }
        p{
            font-size: 12px;
            font-weight: 500;
            margin: 0;
            line-height: 1.2;
            border-radius: 10px;
            text-wrap: nowrap;
        }

    }
    .price-container{
        min-width: 90px;
        font-size: 12px;
        text-align: center;
        position: relative;
        white-space: nowrap;
        margin-top: 4px;
        border-radius: 5px;
        overflow: hidden;
        opacity: 0.5;
        font-style: italic;
        @include themed($Sedeo-themes) {
            color: t('text-dark');
        }
    }
    .topbar-category-item-right{
        padding: 4px 4px 0 4px;
    }

    &.has-selected-items{
        .price-container{
            min-width: 90px;
            font-weight: bold;
            padding: 0 8px;
            opacity: 1;
            @include themed($Sedeo-themes) {
                background-color: t('bg-light');
            }
        }
        .sedeo-rectangle{
            left: 10%;
            opacity: 1;
            z-index: 1;
        }
    }
    &:hover{
        .topbar-category-item-image{
            transform: scale(1.1);
        }
    }
    &.selected-category{
        @include themed($Sedeo-themes) {
            background-color: t('bg-light');
        }
        .topbar-category-item-right{
            @include themed($Sedeo-themes) {
                color: t('text-dark');
            }
        }
    }
}

.carousel-container{
    overflow: hidden;
    >div{
        display: flex;
    }
}
.carousel-item{
    min-width: 200px;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    @include themed($Sedeo-themes) {
        background-color: t('bg-default');
    }
    margin: 0 1rem;
}
.topbar-category-subcategories-list-tooltip{
    padding: 4px 8px;
    border-radius: 10px;
    @include themed($Sedeo-themes) {
        background-color: t('bg-default');
    }
    .loading-container{
        height: 30px;
        width: 40px;
        >span{
            width: 16px !important;
            height: 16px !important;
        }
    }
    >div{
        width: 100%;
        a{
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 12px;
            padding: 4px 8px;
            border-radius: 10px;
            div{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            svg{
                width: 16px;
                height: 16px;
                margin-right: 6px;
            }
            &:hover, &.selected{
                @include themed($Sedeo-themes) {
                    background-color: t('bg-hover');
                }
            }
        }
    }
}


@media (max-width: $SCREEN_MD) {
    .topbar-categories-container{
        height: 90px;
        .loading-container{
            height: 90px;
        }
        .keen-slider{
            .keen-slider__slide{
                min-width: 60px !important;
            }
        }
    }
    .carousel-wrapper {
        .topbar-category-item{
            &-left{
                .topbar-category-item-image{
                    width: 18px;
                    height: 18px;
                    *{
                        max-width: 18px;
                        max-height: 18px;
                    }
                }
            }
            &-right{
                p{
                    font-size: 10px;
                }
            }
            .price-container{
                min-width: fit-content;
                font-size: 8px;
                height: 12px;
            }
        }
    }
}

@media (max-width: $SCREEN_SM) {
    .topbar-categories-container{
        height: 80px;
        .loading-container{
            height: 80px;
        }
    }
}