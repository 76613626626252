.page-bg{
    width: 120vw;
    height: 120vh;
    opacity: 0.5;
    position: fixed;
    object-fit: contain;
    object-position: center top;
    z-index: -1;
}
.sedeo-registration-wrapper{
    .sedeo-registration-form-container{
        padding: 1rem;
        max-width: 600px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .sedeo-logo{
            width: 300px;
            svg{
                width: 100%;
                height: 120px;
            }
        }
        .slogan{
            width: 300px;
            text-align: right;
            font-size: 18px;
            margin-top: -2.2rem;
            margin-right: 1rem;
        }
        .sedeo-description{
            margin: 1rem;
        }

        .sedeo-registration-form{
            width: 100%;
            >h1{
                font-size: 40px;
                text-align: center;
            }
            form{
                width: 100%;
                .input-field {
                    padding: 4px 8px;
                }
                input{
                    text-align: left;
                    padding-left: 1rem;
                }
            }
            .button-container{
                width: 100%;
                display: flex;
                justify-content: center;
                .submit-btn{
                    margin: 1rem;
                    width: 250px;
                    font-size: 18px;
                }
            }
        }
        
        .thankyou-page{
            h1{
                text-align: center;
            }
        }
    }
}


@media (max-width: $SCREEN_SM) {
    .sedeo-registration-wrapper{
        .sedeo-registration-form-container{
            .sedeo-logo{
                width: 200px;
            }
            .slogan{
                width: 230px;
                font-size: 14px;
                margin-top: -3rem;
            }
            .sedeo-description{
                width: 80%;
                p{
                    line-height: 1.2;
                }
                button{
                    margin: 0;
                    padding: 8px 24px;
                    font-size: 14px;
                }
            }
            .roulette-title{
                text-align: center;
                font-size: 24px;
                margin: 0 1rem;
                line-height: 1.4;
            }
            .sedeo-registration-form{
                >h1{
                    font-size: 34px;
                }
            }
            
        }
    }
}