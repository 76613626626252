.maintenance—page{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .sedeo-maintenance-logo{
        width: 400px;
        margin: -12rem 0 2rem;
        svg{
            width: 100%;
            height: 100%;
            overflow: visible;
            #group3{
                transform-origin: bottom;
                animation: float 2s ease-in-out infinite;
            }
            #group2{
                animation: screw 2s 0.4s ease-in-out infinite;
            }
        }
    }
    .title{
        font-size: 42px;
        margin-bottom: 1rem;
    }
    >p{
        line-height: 1.8;
    }
}

@keyframes float {
    0%{
        transform: rotate(-5deg);
    }
    50%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-5deg);
    }
}

@keyframes screw {
    0%{
        transform: translate(-3px, 0);
    }
    50%{
        transform: translate(2px, 0);
    }
    100%{
        transform: translate(-3px, 0);
    }
}