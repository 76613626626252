.product-item-wrapper{
    min-width: 260px;
    margin: 4px;
    flex: 0;
}
.collection-item-images-wrapper{
    position: relative;
    width: 80%;
    margin: auto;
    z-index: 3;
    .collection-item-main-image img{
        border: 4px solid white;
        object-fit: cover;
    }
    .collection-item-left-image {
        position: absolute;
        top: 0;
        transform: translateX(-20px) rotate(-6deg) scale(0.9);
        transition: all 0.4s $FLUID;
        z-index: -1;
    }
    .collection-item-right-image {
        position: absolute;
        top: 0;
        transform: translateX(20px) rotate(6deg) scale(0.9);
        transition: all 0.4s $FLUID;
        z-index: -1;
    }
}
.product-item-container{
    width: 100%;
    //removed this height because it causes Safari to break the item container
    //height: 100%;
    padding: 0.4rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid transparent;
    box-shadow: 0 0 1px 1px lightgray;
    .product-item-image{
        height: 200px;
        overflow: hidden;
        a, div, span, img{
            max-height: 100%;
            height: 100%;
        }
        .lazy-loaded-image {
            width: 100%;
            height: -webkit-fill-available;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.4rem;
            .error-loading-image-container{
                max-width: 120px;
                max-height: 120px;
            }
            img{
                transition: all 0.4s $FLUID !important;
                object-fit: contain;
                width: 100%;
            }
        }
        
    }
    &:hover{
        .product-item-image{
            .collection-item-images-wrapper{
                .collection-item-left-image {
                    transform: translateX(-25px) rotate(-8deg) scale(0.9);
                }
                .collection-item-right-image {
                    transform: translateX(25px) rotate(8deg) scale(0.9);
                }
            }
        }
        .sedeo-button-rounded{
            @include themed($Sedeo-themes) {
                background-color: t('bg-hover');
            }
        }
        .single-product-image img{
            transform: scale(1.1);
        }
    }
    .product-item-content{
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        padding: 8px 8px 0;
        border-radius: 10px;
    }
    &.selected-product{
        box-shadow: none;
        @include themed($Sedeo-themes) {
            border: 1px solid t('sedeo-main-color');
        }
    }
    .sedeo-button-rounded{
        font-size: 12px;
        line-height: 1.2;
        margin: 0.4rem;
        box-shadow: unset;
        &:hover{
            transform: scale(1.1);
        }
    }
}
.suggested-product-item-images{
    height: 160px;
    overflow: hidden;
}
.suggested-product-item-name{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    line-height: 1.2;
}

.product-collection-item-images{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 100px);
}
.collection-item-title{
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    .collection-item-label{
        position: relative;
        @include themed($Sedeo-themes) {
            color: t('sedeo-main-color');
        }
    }
}

.product-item-info{
    span, p, a{
        @include themed($Sedeo-themes) {
            color: t('text-dark');
        }
    }
    h3{
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4;
        margin: 0.5rem 0;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    h4{
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4;
        margin: 0.5rem 0;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.product-item-p-q{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .item-price{
        position: relative;
        overflow: hidden;
        flex: 1;
        min-width: fit-content;
        span{
            display: flex;
            align-items: baseline;
            flex-wrap: nowrap;
            font-size: 12px;
            p{
                display: inline-block;
                font-size: 16px;
                font-weight: 600;
                margin: 0 0.2rem;
            }
        }
        .price-info{
            line-height: 1.2;
            font-size: 11px;
            @include themed($Sedeo-themes) {
                color: t('text-dark');
            }
        }
        .sedeo-rectangle{
            height: 60% !important;
            bottom: -2px;
            width: 80%;
        }
    }
    .product-item-quantity{
        display: flex;
        justify-content: flex-end;
        flex: 1;
        //margin-left: 1rem;
    }
}


@media (max-width: $SCREEN_MD) {
    .product-item-container{
        .product-item-image{
            width: 100%;
            height: 120px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            a{
                width: 120px;
                height: 100%;
            }
        }
        .product-item-content{
            .product-item-info{
                h3{
                    font-size: 13px;
                    font-weight: 600;
                }
                h4{
                    font-size: 12px;
                    font-weight: 400;
                }
            }
            .product-item-p-q{
                .item-price{
                    display: flex;
                    justify-content: center;
                    span{
                        font-size: 10px;
                        h4{
                            font-size: 11px;
                        }
                    }
                }
                .product-item-quantity{
                    display: flex;
                    justify-content: center;
                }
            }
            button{
                font-size: 10px;
            }
        }
    }
}  

@media (max-width: $SCREEN_SM) {
    .product-item-container{
        min-width: 120px;
        .product-item-image{
            .mui-image-iconWrapper {
                width: 100px !important;
                height: 100px !important;
                img{
                    width: 60px;
                    height: 60px;
                }
            }
        }
        .product-item-content{
            .product-item-info{
                h3{
                    font-size: 12px;
                }
                h4{
                    font-size: 12px;
                }
            }
            button{
                font-size: 10px;
                font-weight: 400;
                margin: 0.6rem;
            }
            .product-item-p-q{
                .item-price{
                    span{
                        font-size: 10px;
                    }
                    p{
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }
        .product-collection-item-images{
            grid-template-rows: repeat(2, 60px);
        }
    }
    .suggested-product-item{
        .suggested-product-item-name, .collection-item-title{
            font-size: 10px;
            margin: 4px 0;
        }
        .bottom-container{
            margin: 4px 0;
            p{
                line-height: 1.2;
            }
        }
    }
}