.sedeo-appbar-container{
    justify-content: center;
    align-items: center;
    min-height: $appbar-min-height;
    z-index: 1200;
    @include themed($Sedeo-themes) {
        background-color: t('bg-default');
    }

    .out-of-stock{
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        min-height: 80px;
        transition: all 0.2s ease-in-out;
        @include themed($Sedeo-themes) {
            background-color: t('sedeo-main-color');
            color: t('light-text');
        }
        .out-of-stock-sedeo-icon{
            width: 50px;
            height: 50px;
            div, svg{
                width: 100%;
                height: 100%;
                path{
                    @include themed($Sedeo-themes) {
                        fill: t('light-text');
                    }
                }
            }
        }
        .notes{
            text-align: center;
            margin: 0 1rem;
            strong, span{
                font-size: 14px;
            }
            p{
                font-size: 12px;
                margin: 0;
            }
        }
        .close{
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.2s ease-in-out;
            @include themed($Sedeo-themes) {
                color: t('light-text');
            }
            div{
                height: 100%;
            }
            .close-icon{
                width: 22px;
                opacity: 0.8;
                div, svg{
                    width: 100%;
                    height: 100%;
                    path{
                        @include themed($Sedeo-themes) {
                            fill: t('light-text');
                        }
                    }
                }
            }
            &:hover{
                @include themed($Sedeo-themes) {
                    background-color: t('bg-hover');
                }
                .close-icon svg path{
                    @include themed($Sedeo-themes) {
                        fill: t('text-dark') !important;
                    }
                }
            }
        }
    }

    .close-info-panel{
        display: none;
    }

    .main-appbar{
        min-height: $appbar-min-height;
        width: 100%;
        padding: 0 $MAIN_APP_BORDERS_MARGIN;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #4c4c4c25;
        .search-button{
            flex: 0.95;
            padding: 4px 0.4rem 4px 1.2rem;
            margin: 0 4px;
            font-size: 12px;
            font-weight: 600;
            line-height: 1.2;
            display: flex;
            justify-content: flex-start;
            box-shadow: 0px 0px 10px 1px rgba(black, 0.05) !important;
            transition: all 0.2s $FLUID !important;
            @include themed($Sedeo-themes) {
                background-color: t('bg-default') !important;
                color: t('lighter-grey') !important;
                border: 0.4px solid t('text-disabled') !important;
            }
            svg{
                width: 30px;
                height: 30px;
                padding: 2px;
                border-radius: 50%;
                margin-left: auto;
                @include themed($Sedeo-themes) {
                    color: t('sedeo-main-color');
                }
            }
            // .search-button-label-long{
            //     // opacity: 0;
            //     width: 200px;
            //     // height: 10px;
            //     transition: opacity 1s 0.2s $FLUID, width 1s 0.2s $FLUID;
            //     overflow: hidden;
            //     display: flex;
            //     align-items: center;
            //     text-align: left;
            //     line-height: 1.4;
            // }
            // .search-button-label-short{
            //     opacity: 1;
            //     width: auto;
            //     height: 28px;
            //     transition: opacity 1s 0.2s $FLUID, width 1s 0.2s $FLUID;
            //     overflow: hidden;
            //     display: flex;
            //     align-items: center;
            // }
            &:hover{
                flex: 1;
                @include themed($Sedeo-themes) {
                    background-color: t('bg-light') !important;
                }
            }
        }
        .search-button-mobile{
            width: 30px;
            height: 30px;
            padding: 2px;
            margin: 0 4px;
            font-size: 16px;
            font-weight: 600;
            display: none;
            justify-content: center;
            align-items: center;
            transition: width 0.5s $FLUID, color 0.5s $FLUID !important;
            box-shadow: 0px 0px 10px 1px rgba(black, 0.05) !important;
            transition: all 0.2s ease-in-out !important;
            @include themed($Sedeo-themes) {
                background-color: t('bg-default') !important;
                color: t('lighter-grey') !important;
                border: 0.4px solid t('text-disabled') !important;
            }
            svg{
                width: 100%;
                height: 100%;
                padding: 2px;
                border-radius: 50%;
                @include themed($Sedeo-themes) {
                    color: t('sedeo-main-color');
                }
            }
            &:hover{
                @include themed($Sedeo-themes) {
                    background-color: t('bg-light') !important;
                }
            }
        }
        .left, .right{
            flex: 1;
        }
        .left{
            display: flex;
            align-items: center;
            .logo-short{
                // width: 30px;
                // height: 30px;
                width: 70px;
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
                path{
                    @include themed($Sedeo-themes) {
                        stroke: t('sedeo-main-color');
                    }
                }
                *{
                    max-width: 30px;
                    max-height: 30px;
                }
            }
            .eventbar-container{
                // width: 360px;
                display: flex;
                justify-content: center;
                padding: 0;
                .event-info{
                    // width: 95%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 4px 0.4rem;
                    border-radius: 25px;
                    transition: all 0.5s cubic-bezier(.42,.42,0,1);
                    box-shadow: 0px 0px 10px 1px rgba(black, 0.05);
                    @include themed($Sedeo-themes) {
                        background-color: t('bg-default');
                        border: 0.4px solid t('text-disabled');
                    }
                    >div{
                        display: flex;
                        align-items: center;
                        svg{
                            @include themed($Sedeo-themes) {
                                color: t('sedeo-main-color');
                            }
                        }
                        p{
                            padding-left: 8px;
                            font-size: 13px;
                            margin: 0;
                            font-weight: 400;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            @include themed($Sedeo-themes) {
                                color: t('text-dark');
                            }
                        }
                    }
                    .event-location{
                        border-radius: 20px;
                        padding: 4px 12px 4px 8px;
                        transition: all 0.5s cubic-bezier(.42,.42,0,1);
                        &:hover{
                            @include themed($Sedeo-themes) {
                                background-color: t('bg-light');
                            }
                        }
                    }
                    .event-date{
                        flex: none;
                        justify-content: flex-end;
                        border-radius: 20px;
                        padding: 4px 8px;
                        &:hover{
                            @include themed($Sedeo-themes) {
                                background-color: t('bg-light');
                            }
                        }
                    }
    
                    &:hover{
                        .event-location{
                            // width: 100%;
                            padding: 4px 20px 4px 16px;
                        }
                    }
                    hr{
                        margin: 0 0.6rem;
                        border-right-width: 1px;
                        @include themed($Sedeo-themes) {
                            border-color: t('text-dark');
                        }
                    }
                    .go-back-market{
                        font-size: 28px;
                        padding: 4px;
                        font-weight: 600;
                        display: flex;
                        justify-content: center;
                        transition: width 0.5s $FLUID, background-color 0.5s $FLUID;
                        box-shadow: 0px 0px 10px 1px rgba(black, 0.05);
                        margin-left: 4px;
                        // svg{
                        //     width: 26px;
                        //     height: 26px;
                        //     padding: 2px;
                        //     border-radius: 50%;
                        //     margin-left: 8px;
                        //     @include themed($Sedeo-themes) {
                        //         color: t('bg-default');
                        //     }
                        // }
            
                        &:hover{
                            width: 36px;
                        }
                    }
                    // &.without-event{
                    //     width: 350px;
                    //     &:hover{
                    //         width: 380px;
                    //     }
                    // }
                }
            }
        }
        .right{
            $icons_size: 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .profile-icon, .cart-icon{
                display: flex;
                align-items: center;
                border-radius: 50%;
                margin: 0 4px;
                transition: all 0.2s ease-in-out;
                width: $icons_size;
                height: $icons_size;
                min-width: $icons_size;
                min-height: $icons_size;
                max-width: $icons_size;
                max-height: $icons_size;
                box-shadow: 0px 0px 10px 1px rgba(black, 0.05);
                @include themed($Sedeo-themes) {
                    border: 0.4px solid t('text-disabled');
                    background-color: t('bg-default');
                }
            }
            .profile-icon{
                svg{
                    width: 22px;
                    height: 22px;
                    g[fill]{
                        @include themed($Sedeo-themes) {
                            fill: t('sedeo-main-color');
                        }
                    }
                }
                div{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .profile-letters-icon{
                text-align: center;
                font-size: 20px;
                font-weight: 700;
                width: 100%;
                text-transform: capitalize;
                @include themed($Sedeo-themes) {
                    color: t('sedeo-main-color');
                }
            }
            .cart-icon-container{
                display: flex;
                justify-content: flex-end;
                position: relative;
                .cart-total{
                    height: $icons_size;
                    max-height: $icons_size;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20px;
                    line-height: 1.1;
                    padding: 0;
                    font-size: 10px;
                    margin-right: 0;
                    width: 0;
                    transition: all 0.2s $FLUID;
                    overflow: hidden;
                    opacity: 0;
                    @include themed($Sedeo-themes) {
                        background-color: t('bg-default');
                        border: 0.4px solid t('text-disabled');
                    }
                    .loading-container{
                        width: 14px;
                        height: 14px;
                        >span{
                            width: 10px !important;
                            height: 10px !important;
                        }
                    }
                    p{
                        margin: 0;
                        font-weight: bold;
                        line-height: 1;
                        text-align: center;
                    }
                    &.with-total{
                        width: 100%;
                        padding: 0 2.7rem 0 12px;
                        margin-right: -2.7rem;
                        opacity: 1;
                        text-wrap: nowrap;
                        span{
                            margin-top: 2px;
                        }
                    }
                }
                .cart-icon{
                    svg{
                        width: 26px;
                        height: 26px;
                        path{
                            @include themed($Sedeo-themes) {
                                fill: t('sedeo-main-color');
                            }
                        }
                    }
                    >span, div{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .MuiBadge-badge{
                        top: 8px;
                        right: 5px;
                        pointer-events: none;
                        @include themed($Sedeo-themes) {
                            color: t('bg-default');
                            background-color: t('error');
                        }
                    }
                    &.items-quantity-changed{
                        animation: bounce 0.4s ease-in-out;
                    }
                }
                .handmade-tooltip{
                    // display: none;
                    position: absolute;
                    top: 120%;
                    right: 20%;
                    background-color: white;
                    z-index: 20000;
                    width: 500px;
                    max-width: 500px;
                    max-height: 600px;
                    padding: 1rem;
                    box-shadow: 0px 0px 10px 0px rgba(black, 0.34);
                    border-radius: 20px;
                    // transform-origin: top right;
                    // transform: scale(0);
                    // animation: grow 0.1s ease-in-out reverse;
                }
                .handmade-tooltip.show-tooltip{
                    display: block;
                    // animation: grow 0.1s ease-in-out forwards;
                }
            }
            .hoverable{
                &:hover{
                    transform: scale(1.1);
                    @include themed($Sedeo-themes) {
                        background-color: t('bg-light');
                    }
                }
            }
        }
        .left{
            .logo{
                text-decoration: none;
                margin-right: 1rem;
                img{
                    width: 100px;
                    height: auto;
                }
            }
        }
    }
}

.event-location-tooltip{
    cursor: pointer;
    padding: 4px;
    @include themed($Sedeo-themes) {
        color: t('text-dark') !important;
    }
    .event-venue-name{
        display: flex;
        align-items: center;
        margin: 8px 4px;
        h4{
            font-size: 14px;
            line-height: 1.2;
            margin-left: 4px;
        }
    }
    .event-address{
        display: flex;
        align-items: center;
        margin: 8px 4px;
        p{
            font-size: 14px;
            font-weight: 600;
            line-height: 1.2;
            margin-left: 4px;
        }
    }
    >p{
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: right;
        margin: 4px 0;
    }
    .next-arrow{
        margin-left: 8px;
        transition: transform 0.2s $FLUID;
        @include themed($Sedeo-themes) {
            color: t('sedeo-main-color');
        }
    }
    >h4{
        cursor: pointer;
    }
    &:hover{
        .next-arrow{
            transform: translateX(6px);
        }
    }
    .icon{
        @include themed($Sedeo-themes) {
            color: t('sedeo-main-color');
        }
    }
    &.continue-order-tooltip{
        display: flex;
        align-items: center;
        .continue-order-label{
            font-size: 14px;
        }
    }
}

@media (min-width: $SCREEN_MD) and (max-width: $SCREEN_LG) {
    .main-appbar .right .cart-icon-container .cart-total.cart-total{
        display: none;
    }
}

@media (max-width: $SCREEN_LG) {
    .sedeo-appbar-container{
        .main-appbar{
            padding: 0 2rem;
        }
    }
}

@media (max-width: $SCREEN_MD) {
    .sedeo-appbar-container{
        .main-appbar{
            .left{
                flex: 0;
                .eventbar-container{
                    width: auto;
                    justify-content: left;
                    .event-info, .event-info.without-event{
                        width: fit-content;
                        &:hover{
                            width: fit-content;
                            .event-location{
                                padding: inherit;
                            }
                        }
                        .go-back-market{
                            font-size: 20px;
                            padding: 2px;
                            margin: 0 8px;
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: $SCREEN_SM) {
    $appbar-height: 50px;
    .sedeo-appbar-container{
        min-height: $appbar-height;
        .out-of-stock{
            display: none;
        }
        .main-appbar{
            min-height: $appbar-height;
            padding: 0 8px;
            .right{
                flex: auto;
                .profile-icon, .cart-icon{
                    $pi-size: 30px;
                    width: $pi-size;
                    height: $pi-size;
                    min-width: $pi-size;
                    min-height: $pi-size;
                    max-width: $pi-size;
                    max-height: $pi-size;
                    svg{
                        width: 16px;
                        height: 16px;
                    }
                }
                .cart-icon-container{
                    .cart-icon{
                        .MuiBadge-badge{
                            top: 4px;
                            right: 4px;
                        }
                        svg{
                            width: 20px;
                            height: 20px;
                        }
                    }
                    .cart-total.cart-total{
                        display: none;
                    }
                    .handmade-tooltip{
                        max-width: 90vw;
                    }
                }
                .search-button{
                    display: none;
                }
                .search-button-mobile{
                    display: flex;
                }
            }
            .left{
                flex: auto;
                .logo{
                    display: flex;
                    align-items: center;
                    img{
                        width: 60px;
                    }
                }
                .eventbar-container{
                    .event-info{
                        padding: 1px 0.2rem;
                        hr{
                            margin: 4px 0.2rem;
                        }
                        .event-date, .event-location{
                            padding: 2px 4px;
                        }
                        .go-back-market{
                            font-size: 20px;
                            padding: 2px;
                            width: fit-content;
                            white-space: nowrap;
                            &:hover{
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}