.snackbar-alert{

    font-weight: 600;
    border-radius: 20px;
    @include themed($Sedeo-themes) {
        box-shadow: 0 3px 10px 0 t('text-disabled');
    }

    &-SUCCESS{
        @include themed($Sedeo-themes) {
            background-color: t('sedeo-main-color');
            color: t('light-text');
        }

        .MuiAlert-icon{
            @include themed($Sedeo-themes) {
                color: t('light-text');
            }
        }
    }

    &-ERROR{
        @include themed($Sedeo-themes) {
            background-color: t('error');
            color: t('light-text');
        }

        .MuiAlert-icon{
            @include themed($Sedeo-themes) {
                color: t('light-text');
            }
        }
    }

    &-WARNING{
        @include themed($Sedeo-themes) {
            background-color: t('warning');
            color: t('text-dark');
        }

        .MuiAlert-icon{
            @include themed($Sedeo-themes) {
                color: t('text-dark');
            }
        }
    }

}

