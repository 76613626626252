.sedeo-custom-select{
    margin: 0 1rem;
    box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.08);
    .MuiSelect-select{
        padding: 6px 2rem 6px 1rem;
        font-weight: 500;
    }
    fieldset{
        @include themed($Sedeo-themes) {
            border-color: t('lighter-grey');
        }
    }
    &:hover{
        @include themed($Sedeo-themes) {
            background-color: t('bg-hover');
        }
    }
}
.sedeo-custom-select-popup{
    border-radius: 8px;
    margin-top: 4px;
}


@media (max-width: $SCREEN_SM) {
    .sedeo-custom-select{
        font-size: 12px;
        .MuiSelect-select{
            padding: 8px 1rem 8px 0.8rem;
        }
    }
}