.roulette-page{
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    // background-image: url('/assets/logo-blurred.png');

    .page-bg{
        width: 120vw;
        height: 120vh;
        opacity: 0.5;
        position: fixed;
        object-fit: contain;
        object-position: center top;
        z-index: -1;
    }

    .sedeo-logo{
        width: 300px;
        svg{
            width: 100%;
            height: 120px;
        }
    }

    .slogan{
        width: 300px;
        text-align: right;
        font-size: 18px;
        margin-top: -2.2rem;
        margin-right: 1rem;
    }

    .sedeo-description{
        text-align: center;
        font-weight: 500;
        margin: 1rem;
        display: flex;
        align-items: center;
        button{
            margin: 0 1rem;
            padding-right: 4px;
            svg{
                margin-left: 8px;
                transition: all 0.4s $FLUID;
            }
            &:hover{
                svg{
                    margin-left: 1rem;
                }
            }
        }
    }

    .roulette-title{
        margin: 1rem;
    }

    .roulette-container{
        width: 500px;
        height: 500px;
        position: relative;
        filter: drop-shadow(3px 12px 12px rgb(0 0 0 / 0.4));
        margin: auto;
        .roulette-wrapper{
            width: 100%;
            height: 100%;
            transform: rotate(-90deg);
        }
        .roulette-border-layer{
            width: 410px;
            height: 410px;
            position: absolute;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            animation: rotateMe 20s linear infinite;
            animation-direction: reverse;
            border: 3px dashed gray;
        }
        #canvas{
            width: 100%;
            height: 100%;
            transform: rotate(0);
            transition-property: transform;
            transition-duration: 15s;
            transition-timing-function: $FLUID;
        }
        .spinner-btn{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100px;
            height: 100px;
            padding: 1rem;
            background: #efefef;
            border: none;
            border-radius: 50%;
            color: #444;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            outline: none;
            cursor: pointer;
            transition: all .2s ease-in-out;
            box-shadow: -6px -6px 14px rgba(255, 255, 255, .7),
                        -6px -6px 10px rgba(255, 255, 255, .5),
                        6px 6px 8px rgba(255, 255, 255, .075),
                        6px 6px 10px rgba(0, 0, 0, .20);
            &:hover{
                box-shadow: -2px -2px 6px rgba(255, 255, 255, .6),
                            -2px -2px 4px rgba(255, 255, 255, .4),
                            2px 2px 2px rgba(255, 255, 255, .05),
                            2px 2px 4px rgba(0, 0, 0, .1);
            }
            &:active{
                box-shadow: inset -2px -2px 6px rgba(255, 255, 255, .7),
                            inset -2px -2px 4px rgba(255, 255, 255, .5),
                            inset 2px 2px 2px rgba(255, 255, 255, .075),
                            inset 2px 2px 4px rgba(0, 0, 0, .80);
            }
        }
        .spinner-btn-border-layer{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 3px dashed gray;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100px;
            height: 100px;
            animation: rotateMe 10s linear infinite;
            pointer-events: none;
        }
        .roulette-top-pin{
            position: absolute;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
            transform-origin: 50% 34%;
            svg{
                width: 50px;
                height: 50px;
                filter: drop-shadow(1px 10px 3px rgb(0 0 0 / 0.6));
            }
        }
        .tilt-top-pin{
            animation: tiltMe 0.4s ease-in-out infinite;
        }
        .logo-loading{
            height: 80px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .sedeo-short-logo-loading{
                margin: 0;
                opacity: 0.5;
                width: 60px;
                stroke-linecap: round;
                stroke-dashoffset: 5440;
                stroke-dasharray: 300;
                animation: animateStroke 14s $FLUID forwards;
                svg{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.fireworks-container{
    width: 500px;
    height: 500px;
    position: absolute;
    z-index: 1301;
    pointer-events: none;
}

.prize-dialog{
    padding: 2rem;
    position: relative;
    >p{
        text-align: center;
    }
    .paper-fireworks{
        height: 160px;
        position: absolute;
        top: -30px;
        left: -50px;
        pointer-events: none;
    }
    &-header{
        font-weight: 600;
        font-size: 20px;
    }
    .prize-label{
        font-size: 50px;
        @include themed($Sedeo-themes) {
            color: t('sedeo-main-color');
        }
    }
    .discount-info{
        text-align: center;
        p{
            font-size: 18px;
        }
        .info-label-detail{
            text-align: left;
            margin-top: -8px;
            margin-bottom: 1rem;
        }
        .discount-label{
            font-size: 32px;
            .discount-code{
                font-size: 40px;
                font-weight: 600;
                @include themed($Sedeo-themes) {
                    color: t('sedeo-main-color');
                }
            }
        }
        .discount-code-notice{
            margin-top: -8px;
            margin-bottom: 1rem;
            font-size: 16px;
            @include themed($Sedeo-themes) {
                color: t('lighter-grey');
            }
        }
    }
    .sedeo-prize-slogan{
        text-align: center;
        font-size: 22px;
        margin: 2rem 0;
    }
    .retreive-gift{
        text-align: center;
        margin: 2rem 0;
    }
}


@media (max-width: $SCREEN_SM) {
    .roulette-page{
        padding-top: 8px;
        .sedeo-logo{
            width: 180px;
            margin-top: -1rem;
        }
        .slogan{
            width: 230px;
            font-size: 14px;
            margin-top: -3rem;
        }
        .sedeo-description{
            width: 80%;
            p{
                line-height: 1.2;
            }
            button{
                margin: 0;
                padding: 8px 24px;
                font-size: 14px;
            }
        }
        .roulette-title{
            text-align: center;
            font-size: 24px;
            margin: 0 1rem;
            line-height: 1.4;
        }

        .roulette-container{
            width: 370px;
            height: 370px;
            overflow: hidden;
            .roulette-border-layer{
                width: 310px;
                height: 310px;
            }
            .spinner-btn{
                width: 80px;
                height: 80px;
                font-size: 12px;
            }
            .spinner-btn-border-layer{
                width: 80px;
                height: 80px;
            }
            .logo-loading{
                height: 64px;
                .sedeo-short-logo-loading{
                    width: 50px;
                }
            }
            .roulette-top-pin{
                top: 10px;
            }
        }

        .prize-dialog{
            padding-bottom: 0;
            .prize-dialog-header{
                font-size: 16px;
            }
            .prize-label{
                font-size: 28px;
            }
            .discount-info{
                .info-label-detail{
                    margin-top: 0;
                    line-height: 1.2;
                }
                .discount-label{
                    font-size: 28px;
                    .discount-code{
                        font-size: 32px;
                    }
                }
            }
            .sedeo-prize-slogan{
                margin: 1rem 0;
            }
        }
        .fireworks-container{
            width: 100%;
        }
    }
}