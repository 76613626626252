.category-detail-banner{
    width: 100%;
    height: 350px;
    position: relative;
    @include themed($Sedeo-themes) {
        background-color: t('lighter-grey');
    }
    .lazy-loaded-image, img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .banner-overlay{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.3;
        @include themed($Sedeo-themes) {
            background-color: t('dark');
        }
    }
    .banner-info{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include themed($Sedeo-themes) {
            color: t('light-text');
        }
        .tags{
            max-width: 1000px;
            text-align: center;
        }
    }
}
.category-detail-other-item{
    padding-top: 1rem;
    padding-bottom: 1rem;
    z-index: 10;
    border-radius: 15px;
    margin: 1rem 0;

    @include themed($Sedeo-themes) {
        background-color: t('bg-default');
    }
    h2{
        margin: 0;
        font-size: 20px;
        @include themed($Sedeo-themes) {
            color: t('sedeo-main-color');
        }
    }
    p{
        white-space: pre-line;
        line-height: 1.5;
        margin: 0;
        font-weight: normal;
    }

}

.category-detail-description{
    // padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 15px;
    margin: 1rem 0;

    @include themed($Sedeo-themes) {
        background-color: t('bg-default');
    }
    h1{
        margin: 0;
        margin-bottom: 1rem;
        font-size: 24px;
        @include themed($Sedeo-themes) {
            color: t('sedeo-main-color');
        }
    }
    h2{
        margin: 0;
        font-size: 20px;
        @include themed($Sedeo-themes) {
            color: t('sedeo-main-color');
        }
    }
    p{
        white-space: pre-line;
        line-height: 1.5;
        margin: 0;
        font-weight: normal;
    }

}

.market-sub-categories-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    .market-sub-category-title{
        width: 100%;
        margin: 1rem 0;
    }
    .category-grid-subcategory-item{
        max-width: 300px;
    }
    .category-detail-first-item{
        .limited{
            max-height: 50px; // For Safari, line-clamp does not work for it. Remove this and go to any /catalogue/[categoryId] to see lines not being cut
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .category-detail-other-item{
        p.limited{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .category-detail-description{
        margin-top: 16px;
    }
}

.category-grid{
    display: grid;
    grid-template-columns: repeat(4, 260px);
    grid-gap: 10px;
    justify-content: center;
}
.sticky{
    position: sticky;
    top: $appbar-min-height;
    z-index: 20;
    @include themed($Sedeo-themes) {
        background-color: t('bg-default');
    }
}


.other-subcategories-suggestions{
    margin: 1rem 0;
    h2{
        @include themed($Sedeo-themes) {
            color: t('sedeo-main-color');
        }
    }
    &-list{
        display: flex;
        flex-wrap: wrap;
        >a{
            min-width: 100px;
            max-width: 120px;
            margin: 8px;
            padding: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 10px;
            .subcategory-item-title{
                text-align: center;
                font-weight: 500;
                font-size: 14px;
                @include themed($Sedeo-themes) {
                    color: t('text-dark');
                }
            }
            .subcategory-item-image{
                width: 50px;
                height: 50px;
                transition: all 0.3s $FLUID;
                div{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
                        width: 80%;
                        height: 80%;
                        path{
                            @include themed($Sedeo-themes) {
                                stroke: t('text-dark');
                            }
                            fill: none;
                        }
                    }
                }
            }
            &:hover{
                .subcategory-item-image{
                    transform: scale(1.1);
                }
            }
        }
    }
}




@media (max-width: $SCREEN_LG) {
    .category-grid{
        grid-template-columns: repeat(3, 260px);
    }
}

@media (max-width: $SCREEN_MD) {
    .category-grid{
        grid-template-columns: repeat(2, 260px);
    }
}

@media (max-width: $SCREEN_SM) {
    .sticky{
        top: 60px;
    }
    .category-details-container{
        h1{
            font-size: 18px;
        }
        h2{
            font-size: 14px;
            font-weight: normal;
        }
    }
    .category-grid{
        grid-template-columns: repeat(2, 1fr);
        .product-item-wrapper{
            min-width: 160px;
        }
    }
    .category-detail-first-item{
        h1{
            font-size: 20px;
        }
        h2{
            font-size: 18px;
        }
    }
    .other-subcategories-suggestions-list{
        >a{
            min-width: 80px;
            max-width: 100px;
            .subcategory-item-title{
                font-size: 12px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}


// just for the thinniest smartphones (like the Galaxy Fold 280px screen width)
@media (max-width: 330px) {
    .category-grid{
        grid-template-columns: repeat(1, 1fr);
    }
}