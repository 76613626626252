.max-paragraph-width{
    max-width: 600px;
    font-size: 16px;
    line-height: 1.4;
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: normal;
    white-space: pre-wrap;
}
.product-details-page{
    .loading-container{
        width: 100%;
        span{
            width: 40px !important;
            height: 40px !important;
        }
    }
}
.product-details-card{
    max-width: $HOME_VIEWPORT_MAX_WIDTH;
    margin: 1rem auto;
    padding: 1rem;
}
.product-details-wrapper{
    display: flex;
    .product-gallery{
        width: 33%;
        margin: 1rem;
        .carousel-wrapper{
            .keen-slider{
                width: 100% !important;
                &.thumbnail{
                    .lazy-loaded-image svg{
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
        .gallery-main-image .keen-slider__slide{
            // width: 350px;
            // height: 350px;
            min-width: 100% !important;
            min-width: 100% !important;
            max-height: 350px;
            height: 350px;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.6rem;
            *{
                width: 100% !important;
                height: 100% !important;
            }
            .img-loading-placeholder{
                width: 100%;
                height: 100%;
                margin: 8px auto;
                >div{
                    width: 250px;
                    height: 250px;
                }
            }
            .placeholder-image{
                height: 350px;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 160px;
                    height: 160px;
                }
            }
            .lazy-loaded-image{
                display: flex;
                justify-content: center;
                align-items: center;
                .error-loading-image-container{
                    max-width: 200px !important;
                    max-height: 200px !important;
                }
            }
        }
        .gallery-main-image-placeholder{
            height: 100%;
            max-height: 350px;
            .placeholder-image{
                height: 100%;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.6rem;
                img{
                    width: 140px;
                    height: 140px;
                    max-width: 140px;
                    max-height: 140px;
                }
            }
        }
    }
    .product-details-divider{
        height: 300px;
        margin: auto 2rem auto 0;
        @include themed($Sedeo-themes) {
            border-color: t('text-disabled');
        }
    }
    .product-details{
        width: 66%;
        padding: 1rem;
        .product-details-summary{
            height: 100%;
            display: flex;
            flex-direction: column;
            .subcategory-name{
                font-weight: 400;
                margin: 0;
                strong{
                    text-decoration: underline;
                    margin-left: 4px;
                    font-weight: 600;
                    cursor: pointer;
                    @include themed($Sedeo-themes) {
                        color: t('light-blue');
                    }
                }
            }
            h1{
                font-weight: 500;
            }
            h1, h4{
                margin: 0;
            }
            p{
                font-size: 16px;
                line-height: 1.4;
            }
            
            .product-info{
                display: flex;
                align-items: center;
                margin: 1rem 0;
                align-items: baseline;
                &-label{
                    margin-right: 1rem;
                }
                p{
                    font-weight: bold;
                    margin: 0;
                }
            }
            .product-unit-price{
                display: flex;
                align-items: baseline;
                .bold{
                    font-weight: 600;
                    margin: 0;
                    font-size: 1.6em;
                }
            }
            .product-details-buttons{
                padding: 8px;
                display: flex;
                justify-content: flex-end;
                flex: 1;
                align-items: flex-end;
                .with-selector{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .product-info{
                        margin: 0.2rem 0;
                        &-label{
                            font-size: 18px;
                        }
                        p{
                            font-size: 22px;
                            margin: 0;
                        }
                        .quantity-selector-input > div{
                            font-size: 18px;
                        }
                    }
                }
                &.enter-location{
                    button{
                        margin: 0 8px;
                        font-size: 12px !important;
                    }
                }
            }
            .product-details-info{
                flex: 1;
                display: flex;
                flex-direction: column;
                .product-unit-price{
                    margin: 0.4rem 0;
                    span{
                        text-decoration: underline;
                        display: inline-block;
                        margin-right: 4px;
                        font-weight: 400;
                        font-size: 14px;
                    }
                    .bold{
                        text-decoration: underline;
                        display: inline-block;
                        font-size: 1.6em;
                        font-weight: 600;
                    }
                    .warning{
                        @include themed($Sedeo-themes) {
                            color: t('title');
                        }
                        font-weight: 500;
                        display: block;
                    }
                }
                .info-text{
                    font-size: 12px;
                    line-height: 1.2;
                    @include themed($Sedeo-themes) {
                        color: t('text-dark');
                    }
                }
            }
            // .product-not-available-for-location-wrapper{
            //     margin-top: auto;
            //     padding: 1rem;
            //     border-radius: 6px;
            //     @include themed($Sedeo-themes) {
            //         background-color: t('sedeo-secondary-color-wheat');
            //     }
            //     .product-not-available-for-location-text{
            //         display: flex;
            //         width: 100%;
            //         align-items: center;
            //         svg{
            //             width: 34px;
            //             height: 34px;
            //             margin-right: 8px;
            //         }
            //     }
            // }
            
        }
    }
}
.gallery-main-image .lazy-loaded-image img{
    object-fit: contain;
}
.similar-products-divider{
    width: 90%;
    margin: 2rem auto;
    @include themed($Sedeo-themes) {
        border-color: t('text-disabled');
    }
}
.similar-products-wrapper{
    margin: 3rem 0;
    $PRODUCT_IMAGE_SIZE: 160px;
    .products-suggestions{
        .suggestions-title{
            margin: 1rem;
            font-weight: 400;
            font-size: 14px;
            strong{
                font-weight: 600;
                margin-left: 0.3rem;
                text-decoration: underline;
                @include themed($Sedeo-themes) {
                    color: t('light-blue');
                }
            }
        }
        .carousel-wrapper{
            .keen-slider{
                width: 100% !important;
                &.thumbnail{
                    .lazy-loaded-image svg{
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
    }
    .suggested-product-item{
        padding: 8px;
        width: 200px !important;
        flex: 1;
        cursor: pointer;
        .lazy-loaded-image{
            width: 100%;
            height: $PRODUCT_IMAGE_SIZE;
            .img-loading-placeholder{
                width: 100%;
                height: 100%;
                >div{
                    width: 100px;
                    height: 100px;
                }
            }
        }
        img{
            width: $PRODUCT_IMAGE_SIZE !important;
            height: $PRODUCT_IMAGE_SIZE !important;
            object-fit: contain;
        }
        >div{
            min-height: 230px;
            height: 100%;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            
            p{
                margin: 8px 0;
                font-weight: 400;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            >span{
                width: 100%;
                margin-top: auto;
            }
        }
        .bottom-container{
            width: 100%;
            flex: 1;
            display: flex;
            align-items: flex-end;
            >div{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: baseline;
            }
            span{
                font-size: 10px;
                margin-right: 0.2rem;
                p{
                    margin: 0;
                    font-size: 12px;
                    font-weight: 600;
                    display: inline;
                }
            }
            .sedeo-button-rounded{
                font-size: 12px;
                line-height: 1.2;
                @include themed($Sedeo-themes) {
                    background-color: t('sedeo-main-color');
                }
            }
            button{
                font-size: 10px !important;
                font-weight: 400;
                line-height: 1.4;
            }
        }
        .product-collection-item-images{
            grid-template-rows: repeat(2, 1fr);
            .collection-item-image{
                .lazy-loaded-image{
                    height: $PRODUCT_IMAGE_SIZE / 2;
                }
                img{
                    width: $PRODUCT_IMAGE_SIZE / 2 !important;
                    height: $PRODUCT_IMAGE_SIZE / 2 !important;
                }
            }
        }
        .collection-item-title{
            display: flex;
            flex-direction: column;
            line-height: 1.2;
            .collection-item-label{
                position: relative;
                @include themed($Sedeo-themes) {
                    color: t('sedeo-main-color');
                }
            }
        }
        &:hover{
            .collection-item-images-wrapper{
                .collection-item-left-image {
                    transform: translateX(-25px) rotate(-8deg) scale(0.9);
                }
                .collection-item-right-image {
                    transform: translateX(25px) rotate(8deg) scale(0.9);
                }
            }
        }
    }
}



@media (max-width: $SCREEN_MD) {
    .product-details-wrapper{
        .product-gallery{
            width: 50%;
            margin: 0.4rem;
        }
        .product-details-divider{
            margin: auto 8px auto 0;
        }
        .product-details{
            width: 50%;
            padding: 0.4rem;
            .product-details-summary{
                h1{
                    font-size: 18px;
                }
                .subcategory-name{
                    font-size: 12px;
                    margin: 0;
                }
                p{
                    font-size: 12px;
                }
                .product-unit-price{
                    .bold{
                        margin: 0;
                        font-size: 1.6em;
                    }
                    span{
                        font-size: 12px;
                    }
                }
                .info-text{
                    font-size: 10px;
                }
                .product-details-buttons{
                    button{
                        font-size: 10px !important;
                        line-height: 1.4;
                    }
                }
            }
        }
    }
    .similar-products-wrapper{
        .suggested-product-item{
            width: 160px;
            img{
                width: 100px !important;
                height: 100px !important;
            }
            p{
                font-size: 10px;
            }
        }
    }
}


@media (max-width: $SCREEN_SM) {
    .product-details-card{
        padding: 8px;
    }
    .product-details-wrapper{
        flex-direction: column;
        .product-gallery{
            width: 100%;
            margin: 0.4rem;
            .gallery-main-image{
                .keen-slider__slide{
                    max-height: 200px;
                    height: 200px;
                }
                .lazy-loaded-image{
                    img{
                        max-width: 200px;
                        max-height: 200px;
                    }
                }
            }
            .gallery-main-image-placeholder{
                .placeholder-image{
                    img{
                        width: 80px;
                        height: 80px;
                        max-width: 80px;
                        max-height: 80px;
                    }
                }
            }
        }
        .product-details{
            width: 100%;
            padding: 8px;
        }
        
        .product-details-divider{
            height: 2px;
            width: 90%;
            margin: 2rem auto 1rem;
        }
        .max-paragraph-width{
            font-size: 12px;
        }
    }
    .similar-products-wrapper{
        .products-suggestions{
            h2, .suggestions-title{
                margin: 8px;
                font-size: 10px;
            }
            .carousel-wrapper{
                $PRODUCT_IMAGE_SIZE: 100px;
                .product-collection-item-images{
                    grid-template-rows: repeat(2, 1fr);
                    .collection-item-image{
                        .lazy-loaded-image{
                            height: $PRODUCT_IMAGE_SIZE / 2;
                            img{
                                width: $PRODUCT_IMAGE_SIZE / 2 !important;
                                height: $PRODUCT_IMAGE_SIZE / 2 !important;
                            }
                        }
                        .img-loading-placeholder{
                            width: 100%;
                            height: 100%;
                            >div{
                                width: 100px;
                                height: 100px;
                            }
                        }
                    }
                }
                .suggested-product-item-images{
                    height: $PRODUCT_IMAGE_SIZE;
                }
            }
        }
    }
}