.sedeo-stepper{
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;

    .step-container{
        display: flex;
        align-items: center;
        &:not(:last-child){
            flex: 1;
        }

        .step{
            display: flex;
            flex-direction: row;
            align-items: center;

            .timeline-circle{
                width: 28px;
                height: 28px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid;
                margin: 8px;
                &.inactive{
                    @include themed($Sedeo-themes) {
                        border-color: t('borders-darker');
                    }
                }
                &.active{
                    @include themed($Sedeo-themes) {
                        border-color: t('sedeo-main-color');
                    }
                }
                &.complete{
                    @include themed($Sedeo-themes) {
                        border-color: t('sedeo-main-color');
                        background-color: t('sedeo-main-color');
                    }
                }
                &-active-dot{
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    @include themed($Sedeo-themes) {
                        background-color: t('sedeo-main-color');
                    }
                }
                .check-mark{
                    width: 100%;
                    height: 100%;
                    div{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        svg{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .step-info{
                text-align: center;
                padding: 8px;
                @include themed($Sedeo-themes) {
                    color: t('text-dark');
                }
                h4{
                    margin: 0;
                    font-weight: 400;
                    font-size: 16px;
                }
                &.inactive{
                    @include themed($Sedeo-themes) {
                        color: t('text-disabled');
                    }
                }
            }
            
        }
        .step-connector{
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            span{
                height: 2px;
                width: 100%;
                @include themed($Sedeo-themes) {
                    background-color: t('borders-darker');
                }
            }
            &.complete{
                span{
                    @include themed($Sedeo-themes) {
                        background-color: t('sedeo-main-color');
                    }
                }
            }
        }

        &.disabled-step{
            .step{
                .timeline-circle{
                    background-color: transparent !important;
                    @include themed($Sedeo-themes) {
                        border-color: t('text-disabled');
                    }
                    svg{
                        @include themed($Sedeo-themes) {
                            fill: t('text-disabled');
                        }
                    }
                }
                .step-info{
                    @include themed($Sedeo-themes) {
                        color: t('text-disabled');
                    }
                }
                .helper-icon-disabled-step svg{
                    margin-top: 1.6rem;
                    margin-left: -0.2rem;
                }
            }
        }
    }
}

.disabled-step-tooltip{
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    p{
        color: #4C4C4C;
    }
}

.mobile-step-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 1rem;

    .step-title{
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        margin: 0;
        @include themed($Sedeo-themes) {
            color: t('text-dark');
        }
        span{
            font-size: 14px;
        }
    }
}


@media (max-width: $SCREEN_SM){
    .mobile-step-container{
        .step-title{
            font-size: 14px;
            span{
                font-size: 12px;
            }
        }
    }
}