.cookies-card{
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 300px;
    z-index: 4;
    bottom: 0;
    left: 0;
    margin-bottom: 1rem;
    margin-left: 1rem;
    padding: 1.5rem;
    border-radius: 20px;

    .c-text{
        @include themed($Sedeo-themes) {
            color: t('text-dark');
        }
        font-size: 14px;
        text-align: start;
    }

    .c-container{
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1rem;
    }
    
}