@use "sass:map";
@use "sass:color";

$greyShades: (
    white: #FFFFFF,
    10: #f0f0f0,
    50: #F2F3F5,
    100: #E5E5E5,
    200: #e8e8e8,
    300: #C4C4C4,
    400: #909090,
    500: #555555,
    600: #4C4C4C,
    black: #000000
);


$paleBlueShades: (
    100: #F6F9FC,
    200: #F3F5F9, // Line Stroke
    300: #E3E9EF,
    400: #DAE1E7, // Border
    500: #AEB4BE,
    600: #7D879C, // Low Priority form Title/Text
    700: #4B566B,
    800: #373F50, // Paragraph
    900: #2B3445, // Main Text
);


$primary: (
    100: #00ffb3,
    200: #00ffb3,
    300: #00fa95,
    400: #00f282,
    500: #00ffb3,
    600: #00938A,
    700: #00c453,
    800: #00b246,
    900: #008f2f,
    main: #00D2B6,
    mainLight: #cdffe7,
);

$secondary: (
  orange: #E2796C,
  purple: #8032B3,
  yellow: #EFE85E,
  wheat: #F9EACC,
  gray: #4D4D4D
);

$old-secondary: (
    100: #e8e8ee,
    200: #b9bacb,
    300: #8a8ca8,
    400: #5b5d85,
    500: #213861,
    600: #0F3460,
    700: #101340,
    800: #0e1138,
    900: #0c0e30,
    main: #0F3460,
    dark: #0c0e30,
);
  

$error: (
    100: #FFEAEA,
    200: #FFCBCB,
    300: #FFA9A9,
    400: #FF6D6D,
    500: #FF5353,
    600: #FF4C4C,
    700: #FF4242,
    800: #FF3939,
    900: #FF2929,
    main: #E94560,
    dark: #FF1111,
);


$success: (
    100: #E7F9ED,
    200: #C2F1D1,
    300: #99E8B3,
    400: #52D77E,
    500: #33D067,
    600: #2ECB5F,
    700: #27C454,
    800: #20BE4A,
    900: #0b7724,
    main: rgb(51, 208, 103),
);


$Sedeo-themes: (
    light: (
        bg-disabled: #7b8ba9,
        bg-light-brown: #F9F2EC,
        bg: map.get($greyShades, white),
        bg-default: map.get($greyShades, white),
        light-text: map.get($greyShades, white),
        bg-light: map.get($greyShades, 10),
        table-header: map.get($greyShades, 10),
        bg-hover: map.get($greyShades, 100),
        dark: map.get($greyShades, black),
        in-process: map.get($greyShades, 200),
        text-disabled: map.get($greyShades, 300),
        lighter-grey: map.get($greyShades, 400),
        medium-grey: map.get($greyShades, 500),
        text-dark: map.get($greyShades, 600),
        sedeo-main-color: map.get($primary, main),
        sedeo-main-color-hover: map.get($primary, 600),
        sedeo-main-color-light: map.get($primary, mainLight),
        sedeo-secondary-color: map.get($old-secondary, 500),
        sedeo-secondary-color-orange: map.get($secondary, orange),
        sedeo-secondary-color-purple: map.get($secondary, purple),
        sedeo-secondary-color-yellow: map.get($secondary, yellow),
        sedeo-secondary-color-wheat: map.get($secondary, wheat),
        sedeo-secondary-color-gray: map.get($secondary, gray),
        borders: map.get($paleBlueShades, 200),
        borders-darker: map.get($paleBlueShades, 400),
        sedeo-secondary-color-lighter: #79849d,
        error-light: map.get($error, 400),
        error: map.get($error, main),
        warning: #FFCD4E,
        light-blue: #096ec8,
        purple-light: #7755cc,
        nav-icon: #6a748a,
        sedeo-orange: #ff6b09,
        sedeo-light-orange: #FFB46F,
        title: #da532c,
        delivery: #FDF5E1,
        complete: #E9F8E2,
    )
);


@mixin themed($Sedeo-themes: $themes) {
  @each $theme, $map in $Sedeo-themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($Sedeo-themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
