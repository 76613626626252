.home{

    &-sticky-header{
        position: sticky;
        top: 0;
        z-index: 200;
    }

    &-content{
        .fade-in-section{
            margin: $HOME_SPACE_BETWEEN_SECTIONS 0;
        }
    }

    &-section-1{
        .main-grid{
            flex-direction: row;
            justify-content: center;
            height: 600px;
            border-radius: 34px;
            transition: all 200ms ease;
            position: relative;

            .main-carousel{
                height: 100%;
                .carousel-overlay{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    opacity: 0.4;
                    @include themed($Sedeo-themes) {
                        background-color: t('dark');
                    }
                }
                .carousel-wrapper{
                    height: 100%;
                }
                .keen-slider{
                    height: 100%;
                    width: 100% !important;
                    padding: 0;
                    .keen-slider__slide{
                        min-height: 100%;
                        width: 100% !important;
                        max-width: 100% !important;
                        min-width: 100% !important;
                        overflow: hidden;
                        position: relative;
                        img{
                            width: 100%;
                            height: 100%;
                            object-position: center;
                            object-fit: cover;
                        }
                    }
                }
                .dots{
                    position: absolute;
                    bottom: 1rem;
                    right: 1rem;
                    z-index: 5;
                    .dot{
                        border-radius: 50%;
                        width: 10px;
                        height: 10px;
                        padding: 0;
                        border: none;
                        margin: 0 4px;
                        &.active{
                            @include themed($Sedeo-themes) {
                                background-color: t('sedeo-main-color');
                            }
                        }
                    }
                }
            }
            .main-section-content{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 10;
                text-align: center;
                width: 100%;
                max-width: 1000px;
                .sedeo-rectangle{
                    height: 60% !important;
                    bottom: -2px;
                    width: 80%;
                }

                .sec1-title{
                    @include themed($Sedeo-themes) {
                        color: t('light-text');
                    }
                    width: 100%;
                    font-size: 40px;
                    font-weight: bold;
                    line-height: 1.43;
                    //padding: 0 1rem;
                    margin: 0;
                    border-radius: 10px;

                    span{
                        position: relative;
                        overflow: hidden;

                        &:hover{
                            .sedeo-rectangle{
                                left: 10%;
                                opacity: 1;
                            }
                        }
                    }
                }

                .sec1-description{
                    line-height: 1.43;
                    font-size: 18px;
                    font-weight: 300;
                    margin: 2rem 0;
                    @include themed($Sedeo-themes) {
                        color: t('light-text');
                    }
                    >span{
                        font-weight: bold;
                        position: relative;
                        overflow: hidden;
                        font-size: 20px;
                        transition: all 0.2s $FLUID;
                    }
                }

                .home-button{
                    padding: 0 2rem 0 0.6rem !important;
                    max-width: 400px;
                    .time{
                        width: 50px;
                    }
                }
            }
        }

        .home-partners-container{
            width: 100%;
            padding: 1rem 0;
            @include themed($Sedeo-themes) {
                background-color: t('sedeo-main-color');
            }
        }
        
        .home-partners{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            .lazy-loaded-image{
                width: auto !important;
            }
            &-container{
                height: 70px;
            }
            .keen-slider{
                height: 100%;
                .keen-slider__slide{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: auto;
                    min-width: fit-content !important;
                    max-width: fit-content !important;
                }
            }
            img{
                margin: 1rem;
                width: auto;
                max-width: 100%;
                height: 24px;
                transition: transform 0.2s ease-in-out;
                object-fit: contain;
                &:hover{
                    transform: scale(1);
                }
            }
            .hyatt, .onoff, .eiffage, .prada, .france-tv, .le-monde{
                height: 20px;
            }
            .paris-tech, .renault, .gp-explorer, .panzani{
                height: 30px;
            }
        }
    }

    &-values-section{
        display: flex;
        justify-content: center;
        margin: 2rem;
        .values{
            width: 100%;
            max-width: $HOME_VIEWPORT_MAX_WIDTH;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3rem;

            .values-title{
                max-width: 90%;
                font-size: 34px;
                margin: 50px 0;
            }

            >div{
                margin: 1rem;
                display: flex;
            }
            .category-item-container{
                height: auto;
            }
            .category-item-image{
                box-shadow: unset;
                img{
                    width: 64px;
                    height: 64px;
                    object-fit: contain;
                }
            }
            .category-item-title, .category-item-description{
                height: auto;
            }
            .category-item-title{
                @include themed($Sedeo-themes) {
                    color: t('sedeo-main-color');
                }
            }
        }
    }

    &-section-catalog{
        .home-catalog-title{
            margin-bottom: 1rem;
            font-size: 30px;
            font-weight: 600;
            width: fit-content;
            margin: 2rem auto;
            p{
                line-height: 1.2;
            }
            span{
                transition: all 0.2s $FLUID;
                font-size: 40px;
                @include themed($Sedeo-themes) {
                    color: t('sedeo-main-color');
                }
            }
            // &:hover{
            //     span{
            //         text-shadow: 1px 6px 2px #b1b1b1bf;
            //     }
            // }
        }
        .category-item-container{
            margin: 1rem 0;
            .category-item-image{
                width: 250px;
                height: 250px;
                box-shadow: unset;
                padding: unset;
                .error-placeholder-image{
                    max-width: 80px;
                    margin: auto;
                    path{
                        @include themed($Sedeo-themes) {
                            fill: t('lighter-grey');
                        }
                    }
                }
                .dots{
                    position: absolute;
                    width: 100%;
                    height: auto;
                    bottom: 10px;
                    .dot{
                        border-radius: 50%;
                        height: 8px;
                        width: 8px;
                        padding: 0;
                        margin: 0 2px;
                        @include themed($Sedeo-themes) {
                            border: 1px solid t('text-disabled');
                        }
                        &.active{
                            @include themed($Sedeo-themes) {
                                background-color: t('sedeo-main-color');
                            }
                        }
                    }
                }
            }
            .category-item-text-container{
                .category-item-title{
                    height: 34px;
                    @include themed($Sedeo-themes) {
                        color: t('sedeo-main-color');
                    }
                }
                .category-item-subtitle{
                    font-weight: 300;
                    line-height: 1.2;
                }
            }
        }
        .catalog-more-info{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 2rem;
            text-align: center;
            .question-mark{
                width: 40px;
                height: 40px;
                svg{
                    width: 100%;
                    height: 100%;
                    @include themed($Sedeo-themes) {
                        fill: t('lighter-grey');
                    }
                }
            }
            .info-text{
                max-width: 400px;
                margin: 1rem;
                padding: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
                @include themed($Sedeo-themes) {
                    background-color: t('bg-light-brown');
                }
                .email-address{
                    margin: 0 4px;
                    font-weight: 500;
                    text-decoration: underline;
                    @include themed($Sedeo-themes) {
                        color: t('sedeo-main-color');
                    }
                }
            }
        }
    }

    &-section-blog{
        margin: 4rem 0;
        .blog-section-title{
            margin-left: 16vw;
            h4{
                font-size: 22px;
                @include themed($Sedeo-themes) {
                    color: t('sedeo-main-color');
                }
            }
        }
        .articles{
            width: 100%;
            display: flex;
            gap: 2rem;
            margin-bottom: 1rem;
            .margin-div{
                width: 15vw !important;
            }
            .home-blog-post{
                position: relative;
                width: 400px !important;
                min-width: 400px !important;
                max-width: 400px !important;
                height: 260px;
                border-radius: 10px;
                cursor: pointer;
                .post-image{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    .lazy-loaded-image, .image-overlay{
                        width: 100%;
                        height: 100%;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            transition: transform 0.2s $FLUID;
                        }
                    }
                    .lazy-loaded-image{
                        position: absolute;
                        object-fit: cover;
                        transform: scale(1);
                    }
                    .image-overlay{
                        background: linear-gradient(#00000000, #000000);
                        opacity: 0.4;
                    }
                }
                .blog-info{
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: 0.4rem 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @include themed($Sedeo-themes) {
                        color: t('light-text');
                    }
                    h4{
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .next-icon{
                        width: 30px;
                        min-width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        opacity: 0;
                        transform: translateY(20px);
                        transition: all 0.2s $FLUID;
                        @include themed($Sedeo-themes) {
                            border: 1px solid t('light-text');
                        }
                    }
                }
                &:hover{
                    img{
                        transform: scale(1.05);
                    }
                    .next-icon{
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
        .home-button{
            margin: 2rem auto 0;
        }
    }

    &-screenshot-bigger-container{
        justify-content: flex-end;
    }

    &-screenshot-container{
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        flex-wrap: wrap;
        width: 93%;

        .left{
            flex: 1;
            width: 600px;
            max-width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            @include themed($Sedeo-themes) {
                color: t('text-dark');
            }

            >div{
                display: flex;
                margin: 1rem 0;
            }


            p{
                font-size: $main-title-size;
                font-weight: bold;
                line-height: 1.43;
                margin: 0;
                padding: 0 1rem;
            }

            .title-bottom{
                margin: 1rem 0;
            }

            .description{
                margin: 2rem 0;
                font-size: 18px;
                max-width: 600px;
                line-height: 1.43;
                font-weight: normal;
            }
        }

        .right{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            max-width: 50%;
            >div{
                display: flex;
                width: 90%;
                height: 700px;
                overflow: hidden;
                padding-left: 50px;
                transition: all 0.2s ease-in-out;
                padding: 2rem 4rem;
                img{
                    transform: rotateZ(2deg);
                    transition: all 0.2s ease-in-out;
                    border: 1px solid lightgray;
                    border-radius: 15px;
                }
            }
        }

        &:hover{
            .right{
                >div{
                    padding-left: 2rem;
                    img{
                        transform: rotateZ(-4deg);
                    }
                }
            } 
        }
    }

    &-stats-section{
        width: 100%;
        margin: 3rem 0;
        @include themed($Sedeo-themes) {
            background-color: t('bg-light');
        }
        .stats-wrapper{
            width: 90%;
            margin: auto;
            padding: 3rem 2rem;
            max-width: 1000px;
            display: flex;
            .left{
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                h2{
                    font-size: 80px;
                    line-height: 0.6;
                }
                p{
                    font-size: 30px;
                }
            }
            .stat{
                display: flex;
                flex-direction: column;
                width: 260px;
                margin: 2rem 0;
                @include themed($Sedeo-themes) {
                    color: t('text-dark')
                }
                .title{
                    font-size: 36px;
                    font-weight: bold;
                    margin: 0;
                    margin-bottom: -10px;
                    line-height: 1.2;
                    @include themed($Sedeo-themes) {
                        color: t('sedeo-main-color');
                    }
                }
                p{
                    font-size: 24px;
                    margin: 0;
                }
            }
        }
    }

    &-reviews-container{
        max-width: 1000px;
        width: 90%;
        margin: 4rem auto;
        h4{
            font-size: 22px;
        }
        .reviews{
            margin-top: 4rem;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .left, .right{
                display: flex;
                flex-direction: column;
            }
        }

        .review-box{
            width: 460px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin: 1rem;
            border-radius: 25px;
            padding: 2rem;
            @include themed($Sedeo-themes) {
                background-color: t('bg-light-brown');
                color: t('text-dark');
            }
            
            .comment{
                font-size: 18px;
                margin: 0;
                font-weight: normal;
            }

            .reviewer{
                display: flex;
                margin-top: 1rem;
                .quote{
                    width: 70px;
                    height: 70px;
                    margin: 0 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    div, svg{
                        width: 100%;
                        height: 100%;
                    }
                }

                &-info{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    h4{
                        font-size: 18px;
                        font-weight: bold;
                        margin: 0;
                    }
                    p{
                        font-size: 16px;
                        margin: 0;
                        line-height: 1.2;
                    }
                }
            }
        }

        .button-container{
            display: flex;
            justify-content: center;
            padding: 2rem;
        }
    }

    &-perks-section{
        display: flex;
        justify-content: center;
        .perks-container{
            width: 90%;
            display: flex;
            justify-content: center;
            max-width: $HOME_VIEWPORT_MAX_WIDTH;
            padding: 0 1rem;

            .perks-text{
                flex: 1;
                display: flex;
                flex-direction: column;
                .title{
                    @include themed($Sedeo-themes) {
                        color: t('text-dark');
                    }
                    h1{
                        display: inline;
                        font-size: $main-title-size;
                        font-weight: bold;
                    }
                    .g-bg{
                        margin: 0 0.5rem;
                    }
                }
            }

            .image-container{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 90%;
                    max-width: 700px;
                }
            }

            .perk{
                display: flex;
                margin: 1rem;
                border-radius: 10px;
                @include themed($Sedeo-themes) {
                    color: t('text-dark');
                }
                >div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    max-width: 340px;
                }
                img{
                    width: 100px;
                    height: 100px;
                    transition: transform 0.1s ease-in-out;
                }
                h3{
                    font-size: 18px;
                    font-weight: bold;
                    margin: 0 0 0.5rem;
                }
                p{
                    font-size: 16px;
                    margin: 0;
                    line-height: 1.43;
                }
                &:hover{
                    background-color: #ebebeb;
                    img{
                        transform: scale(1.2);
                    }
                }
            }
        }
    }

    &-ecology{
        padding: 2rem 0;
        margin: 2rem 0;
        &-container{
            width: 80%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            max-width: 1000px;
            gap: 1rem;
            margin: auto;
            .ecology-stat{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: transform 0.2s ease-in-out;
                width: 260px;
                border-radius: 25px;
                padding: 1rem 2rem;
                @include themed($Sedeo-themes) {
                    background-color: t('bg-light-brown');
                    color: t('text-dark')
                }

                .title{
                    font-size: 36px;
                    font-weight: bold;
                    margin: 0;
                    margin-bottom: -10px;
                }
                p{
                    font-size: 24px;
                    margin: 0;
                }
            }
            .title{
                flex: 2;
                p{
                    font-size: 22px;
                    margin: 0;
                    width: fit-content;
                    font-weight: 500;
                    display: inline;
                }
                .we-saved{
                    margin-left: 4px;
                }
            }
    
            .additional-info{
                flex: 1;
                max-width: 200px;
                span{
                    display: inline-block;
                    padding: 0 4px;
                    font-size: 16px;
                    @include themed($Sedeo-themes) {
                        color: t('text-dark')
                    }
    
                    &:nth-child(2){
                        font-weight: 700;
                        text-decoration: underline;
                    }
                }
            }
    
            &:hover{
                .stat-container{
                    transform: scale(1.05);
                }
            }
        }
    }

    &-start-shopping-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .home-slogan{
            font-size: 32px;
            margin: 1rem 0;
            font-style: italic;
            h4{
                display: inline;
                margin-right: 4px;
                font-weight: 500;
            }
            span{
                font-weight: 300;
            }
        }
    }

    &-button{
        width: fit-content !important;
        max-width: 450px;
        padding: 0.8rem 2rem !important;
        min-height: 60px !important;
        font-size: 22px !important;
        font-weight: 500 !important;
        transition: all 0.2s ease-in-out !important;
        display: flex;
        align-items: center;
        gap: 1rem;
        margin: auto;
        &:hover{
            transform: scale(1.05) !important;
        }
    }

    
}



// ************* for different screen sizes *************

@media (max-width: $SCREEN_LG) {
    .home{
        &-ecology-container{
            width: 90%;
            .title{
                p{
                    font-size: 22px;
                    font-weight: bold;
                    line-height: 1.4;
                }
            }
    
            .stat-container{
                >div{
                    padding: 1rem;
                    .title{
                        font-size: 24px;
                    }
                    p{
                        font-size: 20px;
                    }
                }
            }
    
            .additional-info{
                span{
                    font-size: 14px;
                }
            }
        }

        &-reviews-container{
            .review-box{
                width: 370px;
                margin: 0.6rem;
                padding: 1rem;
            }
        }
    }
}

@media (max-width: $SCREEN_MD) {
    .home-button{
        padding: 0rem 1.5rem 0rem 1.2rem !important;
        >div{
            height: 50px !important;
            svg{
                width: 50px !important;
                height: 50px !important;
            }
        }
    }
    .home{
        &-content{
            .topbar-categories-container .carousel-wrapper, .main-grid .main-carousel .carousel-wrapper{
                margin-top: 0;
            }
        }
        &-section-1{
            .main-grid{
                height: 440px;
                background-color: transparent !important;
                padding: 0;
                .main-section-content{
                    width: 90%;
                    margin: auto;
                    .sec1-title{
                        font-size: 30px !important;
                    }
                    .home-button .time{
                        margin-right: 0.4rem;
                    }
                }
            }
            .home-partners{
                img{
                    margin: 0.4rem;
                    padding: 0.2rem;
                    height: 35px;
                    transition: transform 0.2s ease-in-out;
                    &:hover{
                        transform: scale(1.1);
                    }
                }
            }
        }

        &-categories-container{
            margin: 4rem 2rem;
        }

        &-section-catalog{
            .catalog-more-info{
                margin: 2rem 0;
            }
        }

        &-stats-section{
            .stats-wrapper{
                .left{
                    justify-content: flex-start;
                    h2{
                        font-size: 60px;
                    }
                    p{
                        font-size: 24px;
                    }
                }
                .right{
                    .stat{
                        width: auto;
                    }
                }
            }
        }

        &-reviews-container{
            max-width: 500px;
            .reviews{
                margin-top: 2rem;
            }
            .review-box{
                width: 230px;
                margin: 0.6rem;
                padding: 1rem;
                .comment{
                    font-size: 12px;
                }
                .reviewer{
                    .quote{
                        width: 40px;
                        height: 40px;
                        margin: 0 0.6rem
                    }
                    .reviewer-info{
                        h4, p{
                            font-size: 12px;
                        }
                    }
                }
            }
            .title{
                h2{
                    display: inline;
                    font-size: 34px;
                }
                .g-bg{
                    margin: 0 0.5rem
                }
            }
            .description{
                font-size: 16px;
            }
        }

        &-perks-section{
            .perks-container{
                flex-direction: column-reverse;
                max-width: 70%;

                .perks-text{
                    margin-bottom: 6rem;
                    .title{
                        h1{
                            font-size: 34px;
                        }
                    }
                }
            }
        }

        &-ecology-container{
            width: 90%;
            .title{
                p{
                    font-size: 14px;
                }
            }
    
            .stat-container{
                padding: 1rem;
                width: 140px;
                .title{
                    font-size: 18px;
                }
                p{
                    font-size: 14px;
                }
            }
    
            .additional-info{
                span{
                    font-size: 12px;
                }
            }
        }

        &-start-shopping-container{
            width: 90%;
            margin: auto;
            .left{
                padding: 1rem;
                h2{
                    font-size: 22px;
                }
                p{
                    font-size: 14px;
                }
                button{
                    font-size: 14px !important;
                    padding: 0 1rem !important;
                    min-height: 40px !important;
                }
            }
        }
        
        &-values-section{
            .values{
                gap: 1rem;
            }
        }
    }
}

@media (max-width: $SCREEN_SM) {
    $main-title-size: 22px;
    .home{

        &-section-1{
            .main-grid{
                .main-section-content{
                    .sec1-title{
                        font-size: $main-title-size !important;
                    }
                    .sec1-description{
                        font-size: 20px;
                        max-width: 80%;
                        margin: 1.4rem auto;
                        span{
                            font-size: 20px;
                        }
                    }
                    &:hover{
                        .sec1-description{
                            span{
                                font-size: inherit;
                            }
                        }
                    }
                    .home-button{
                        .time{
                            margin-left: -3rem;
                        }
                    }
                }
            }
        }

        &-categories-container{
            padding: 0;
            margin: 4rem 0;
        }

        &-section-catalog{
            .category-item-container{
                .category-item-image{
                    width: 100%;
                    height: 170px;
                }
            }
        }

        &-values-section{
            margin: 2rem 1rem;
            .category-item-container{
                padding: 0;
            }
            .values-title{
                font-size: $main-title-size;
            }
            .values{
                flex-wrap: wrap;
                max-width: 100%;
                gap: 0;
                .category-item-container{
                    max-width: 140px;
                    min-width: 90px;
                    margin: 0.2rem 0.8rem;
                    .category-item-image{
                        img{
                            width: 34px;
                            height: 34px;
                        }
                    }
                    .category-item-text-container{
                        .category-item-text{
                            padding: 0;
                        }
                    }
                }
            }
        }

        &-partners{
            img{
                margin: 0.5rem;
                height: 35px;
            }
        }

        &-stats-section{
            margin: 2rem 0;
            .stats-wrapper{
                width: 100%;
                padding: 0 2rem;
                .left{
                    justify-content: flex-start;
                    h2{
                        font-size: 40px;
                    }
                    p{
                        font-size: 16px;
                    }
                }
                .right{
                    .stat{
                        width: auto;
                        .title{
                            font-size: 26px;
                        }
                        p{
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        &-section-blog{
            .blog-section-title{
                margin-left: 10vw;
                h4{
                    font-size: 18px;
                }
                p{
                    font-size: 14px;
                }
            }
            .articles{
                .margin-div{
                    width: 9vw !important;
                }
                .home-blog-post{
                    width: 300px !important;
                    min-width: 300px !important;
                    max-width: 300px !important;
                    height: 195px;
                }
            }
        }

        &-reviews-container{
            max-width: 400px;
            width: 100%;
            padding: 0 10vw;
            margin: 2rem auto;
            h4{
                font-size: 18px;
            }
            .description{
                display: flex;
                justify-content: center;
                p{
                    font-size: 16px;
                    max-width: 300px;
                }
            }

            .reviews{
                margin-top: 2rem;
            }

            .review-box{
                width: 100%;
                padding: 1rem;
                margin: 0.5rem 0;
                
                .comment{
                    font-size: 14px;
                    font-weight: normal;
                }
    
                .reviewer{
                    margin-top: 1rem;
                    .quote{
                        width: 40px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        div{
                            width: 60px;
                        }
                    }
    
                    &-info{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        h4{
                            font-size: 14px;
                            font-weight: bold;
                            margin: 0;
                        }
                        p{
                            font-size: 12px;
                            margin: 0;
                        }
                    }
                }
            }
            .button-container{
                padding: 2rem 0;
            }
        }

        &-perks-section{
            .perks-container{
                max-width: 90%;
                .perks-text{
                    .title{
                        margin-left: 2rem;
                        h1{
                            font-size: 28px;
                        }
                    }
                    .perk{
                        margin: 1rem 0;
                        img{
                            width: 80px;
                            height: 80px;
                        }
                        div{
                            max-width: 240px;
                        }
                        h3{
                            font-size: 16px;
                        }
                        p{
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        &-ecology-container{
            width: 80%;
            flex-direction: column;
            text-align: center;
            .title{
                display: flex;
                flex-direction: column;
                align-items: center;
                p{
                    font-size: 20px;
                    font-weight: bold;
                }
            }
    
            .stat-container{
                margin: 2rem 0;
                >div{
                    padding: 1rem;
                    .title{
                        font-size: 24px;
                    }
                    p{
                        font-size: 16px;
                    }
                }
            }
    
            .additional-info{
                span{
                    font-size: 16px;
                }
            }
        }

        &-start-shopping-container{
            width: 100%;
            padding: 0 10vw;
            .home-slogan{
                font-size: 18px;
            }
            .home-button{
                width: 100% !important;
            }
        }
    }

    .home-button{
        font-size: 14px !important;
        padding: 0 1rem !important;
        min-height: 40px !important;
        width: 80% !important;
    }
}

@media (max-width: 330px) {
    .home-section-1 .main-grid .content-grid {
        .sec1-description{
            font-size: 20px;
        }
        .sec1-title h1{
            font-size: 28px !important;
        }
    }
    .home-section-catalog{
        .category-item-container{
            margin: 1rem auto;
        }
    }
    .home-reviews-container{
        .title h2{
            font-size: 22px;
            span{
                display: block;
            }
        }
        .description{
            margin: 1rem 0;
            padding: 0 3rem;
            p{
                font-size: 14px;
            }
        }
        .review-box{
            width: 90%;
            margin: 0.5rem auto;
        }
    }
    .home-values-section .values-container {
        .values-title{
            font-size: 22px;
            margin: 50px 2rem;
        }
    }
    .home-stats-section{
        margin: 2rem 0;
        .stats-wrapper{
            width: 100%;
            padding: 1rem;
            flex-direction: column;
            .left{
                justify-content: center;
                margin: 1rem 0;
                h2{
                    font-size: 40px;
                }
                p{
                    font-size: 16px;
                }
            }
            .right{
                display: flex;
                flex-direction: column;
                align-items: center;
                .stat{
                    margin: 0.6rem 0;
                    width: fit-content;
                    .title{
                        font-size: 26px;
                    }
                    p{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}