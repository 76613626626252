.order-stepper-container{
    margin: 0 auto;
    padding: 0.4rem 2rem;
    z-index: 200;
    width: 99vw;
    @include themed($Sedeo-themes) {
        background-color: t('bg-default');
    }
}
.order-recap-header{
    display: flex;
    margin: 1rem auto;
    &-info{
        flex: 1;
        align-self: center;
        text-align: center;
        h4{
            font-weight: 400;
        }
    }
}
.product-image-name{
    max-width: 140px;
    margin: auto;
    a{
        @include themed($Sedeo-themes) {
            color: t('text-dark');
        }
    }
    img{
        object-fit: contain;
    }
}
.order-card-title{
    text-align: center;
    margin: 1rem 0;
    font-size: 18px;
    font-weight: 500;
}
.order-card-created-at{
    text-align: center;
    margin-top: -1rem;
    margin-bottom: 1rem;
}
.order-card-subtitle{
    font-size: 18px;
    margin: 1rem 8px;
}
.order-card-container{
    padding: 1rem;
    margin: 1rem 8px 0;
    border-radius: 20px;
    .return-contact-phone{
        padding-top: 1rem;
    }
    .handling-notice{
        display: flex;
        align-items: center;
        gap: 0.6rem;
        margin: 1.4rem 0;
        @include themed($Sedeo-themes) {
            color: t('sedeo-orange');
        }
    }
    .handling-selector{
        margin: 1rem 0;
        >div{
            display: flex;
            align-items: center;
            button{
                margin: 0 8px;
            }
        }
        >p{
            margin: 0;
            padding: 0;
        }
        .input-field{
            >div{
                width: fit-content;
                input{
                    width: auto;
                }
            }
        }
    }
    .handling-total{
        text-align: center;
        >strong{
            margin-left: 6px;
        }
    }
    .handling-warning{
        margin-top: 1rem;
        @include themed($Sedeo-themes) {
            color: t('sedeo-orange');
        }
        svg{
            vertical-align: text-bottom;
            margin-right: 8px;
        }
    }
    .schedule-field{
        width: 100%;
        height: 100%;
        padding: 0 8px;
        margin: 0.6rem 0px;
        >div{
            display: flex;
            align-items: center;
            gap: 1rem;
        }
        .field-title{
            width: 200px;
        }
        &.has-error{
            p{
                text-align: right;
                @include themed($Sedeo-themes) {
                    color: t('error');
                }
            }
            button{
                @include themed($Sedeo-themes) {
                    border-color: t('error');
                }
            }
        }
    }
    .shipping-notice{
        text-align: center;
    }
    .order-validation-notes{
        text-align: center;
        max-width: 600px;
        margin: auto;
        >p:not(:last-of-type){
            margin: 8px 0;
        }
        >span{
            font-size: 12px;
        }
        >p.highlighted{
            font-weight: 600;
            margin: 1rem 0;
        }
        .order-payment-info-bloc{
            margin: 1.6rem 0;
            .email{
                text-decoration: underline;
            }
        }
    }
    
    hr{
        margin: 0.2rem 1rem;
    }
    .remote-cart-item-subcategory{
        text-align: left;
        margin-left: 1rem;
    }
    .remote-cart-item{
        display: flex;
        h2{
            text-align: left;
        }
        &:hover{
            @include themed($Sedeo-themes) {
                background-color: t('bg-hover');
            }
        }
    }
    .billing-address-container{
        width: 100%;
        .billing-address-field{
            padding: 0.4rem;
            margin: 1rem 0;
        }
    }
}

.no-passed-orders{
    .empty-cart-container{
        .empty-cart-info{
            max-width: 600px;
            .view-catalog-btn{
                font-weight: 700;
                font-size: 16px;
            }
        }
    }
}
.order-status-chip{
    padding: 6px;
    font-size: 12px;
    border-radius: 6px;
}
.status-chip-order-view{
    display: flex;
    align-items: center;
    .order-status-chip{
        margin: 0 8px;
    }
}
.orders-row-mobile{
    margin-top: 1rem;
    padding: 0 1rem 1rem 1rem;
    border-radius: 20px;
    &-line{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 8px 0;
        >div{
            flex: 1;
        }
        h2{
            width: 100%;
        }
    }
    .view-order{
        float: right;
        svg{
            margin-left: 8px;
        }
    }
}
.order-recap-address{
    margin: 1rem 0;
    width: auto;
    .left{
        h2{
            text-align: left;
        }
    }
    .right{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .company-name{
            font-weight: 700;
        }
    }
}
.order-validation-price-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
    >div{
        display: flex;
        width: 100%;
        margin: 2px 0;
        >div{
            flex: 1;
            &:first-child{
                margin-right: 8px;
                text-align: right;
            }
            &:last-child{
                margin-left: 8px;
            }
        }
    }
    hr{
        width: 200px;
    }
}
.price-info-line{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 0;
    @include themed($Sedeo-themes){
        color: t('text-dark');
    }
    .pricing-notice, .discount-notice, .applied-discount{
        font-size: 12px;
        margin-top: -3px;
        line-height: 1.2;
    }
    .pricing-notice{
        @include themed($Sedeo-themes) {
            color: t('lighter-grey');
        }
        svg{
            margin-bottom: -2px;
        }
    }
    .discount-notice, .applied-discount{
        @include themed($Sedeo-themes) {
            color: t('sedeo-secondary-color-orange');
        }
    }
    .total-amount{
        font-size: 18px;
    }
    p{
        font-size: 14px;
        margin: 0;
    }
    .tip{
        display: flex;
        align-items: center;
        font-size: 10px;
        margin-top: -4px;
        svg{
            font-size: 14px;
        }
    }
    &.discount{
        margin-top: -6px !important;
    }
    .help-icon{
        margin-left: 4px;
        vertical-align: middle;
        @include themed($Sedeo-themes) {
            color: t('sedeo-orange');
        }
    }
}
.coefficient-tooltip{
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #555555;
    @include themed($Sedeo-themes) {
        color: t('medium-grey');
    }
    .tooltip-text-coef{
        font-size: 14px;
        text-align: center;
        margin: 0;
        color: #555555;
        font-weight: 400;
        @include themed($Sedeo-themes) {
            color: t('medium-grey');
        }
        strong{
            margin-left: 4px;
        }
    }
    .divider{
        width: 50%;
        margin: 1rem 0;
        border-color: #DAE1E7;
        @include themed($Sedeo-themes) {
            border-color: t('borders-darker');
        }
    }
}
.empty-cart-button{
    font-size: 14px;
    font-weight: 400;
}
.right-panel{
    position: sticky;
    top: 156px;
    .order-cart-price{
        margin: 1rem 8px;
        padding: 1rem;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        .known-venue-button-notice{
            line-height: 1.2;
            font-size: 12px;
            margin-top: 1rem;
            @include themed($Sedeo-themes) {
                color: t('sedeo-orange');
            }
        }
    }
    .order-cart-partner{
        margin: 1rem 8px;
        padding: 1rem;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        >h4{
            font-size: 14px;
            font-weight: 500;
            text-align: center;
        }
        .partner-code{
            display: flex;
            align-items: center;
            width: 100%;
            .apply-button{
                font-size: 14px;
                font-weight: 400;
                white-space: pre-line;
                width: 80px;
            }
        }
        .discount-codes-list-container{
            .discount-code-row{
                display: flex;
                align-items: center;
                position: relative;
                margin: 8px 0;
                border-radius: 4px;
                @include themed($Sedeo-themes) {
                    background-color: t('bg-light');
                }
                .line-marker{
                    width: 5px;
                    height: 40px;
                    border-radius: 4px;
                    margin-right: 4px;
                    @include themed($Sedeo-themes) {
                        background-color: t('sedeo-main-color');
                    }
                }
                button{
                    border-radius: 50%;
                    width: 24px;
                    height: 24px;
                    margin-left: auto;
                    margin-right: 8px;
                    padding: 0;
                    @include themed($Sedeo-themes) {
                        background-color: t('bg-default');
                    }
                }
            }
        }
    }
}

.timeslots-list{
    width: 100%;
    >div{
        border-radius: 20px;
        max-width: 400px;
    }
    .accordion-container{
        .accordion-menu-title{
            font-weight: 500;
        }
        .accordion-menu-details{
            padding: 8px;
        }
    }
    .MuiBackdrop-invisible{
        max-width: 100%;
        width: 100%;
    }
}
.pricing-and-payment{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    >div{
        flex: 1;
    }
    .payment-notice{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2rem 0;
        &-line{
            margin: 2rem 0;
            min-width: 180px;
        }
    }
}
.shipping-total-summary-card{
    position: sticky;
    display: flex;
    flex-direction: column;
}
.recap-date-hour-info{
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    padding: 1rem 0;
    @include themed($Sedeo-themes) {
        border-top: 1px solid t('lighter-grey');
        border-bottom: 1px solid t('lighter-grey');
    }
    >div:first-child{
        flex: 1;
        padding-right: 8px;
        @include themed($Sedeo-themes) {
            border-right: 1px solid t('lighter-grey');
        }
    }
    >div:nth-child(2){
        flex: 1;
        padding-left: 1rem;
    }
    hr{
        height: 360px;
        margin: auto 2rem;
    }
    h2{
        text-align: left;
        margin-bottom: 0;
    }
    .delivery-info{
        margin: 2rem 0;
    }
}
.total-order-card{
    display: flex;
    flex-direction: column;
}

.order-saving-co2{
    text-align: center;
    p{
        font-size: 14px;
    }
    .italic{
        font-style: italic;
        font-size: 10px;
        margin-top: -4px;
    }
    .saving-numbers{
        font-weight: 600;
        font-size: 16px;
    }
}
.order-payment-validation-dialog{
    text-align: center;
    .MuiDialog-container .MuiPaper-root{
        width: 100%;
        max-width: 380px;
    }
    &-title{
        font-size: 18px;
    }
    &-content{
        p:first-child{
            margin-bottom: 1rem;
        }
        .parenthesis{
            margin: -4px 0 1rem;
            font-size: 10px;
        }
    }
    &-actions{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        .validation-button{
            padding: 8px 40px;
        }
    }
}

.products-table{
    table-layout: fixed;
    margin: 1rem auto;
    padding: 0 1rem;
    width: 90%;
    thead{
        @include themed($Sedeo-themes) {
            border-bottom: 1.5px solid t('borders-darker');
        }
    }
    th{
        padding-bottom: 4px;
        line-height: 1.2;
        font-weight: bold;
    }
    .quantity-cell{
        width: 140px;
    }
    .divider{
        display: table-row;
    }
    td, th{
        padding: 8px;
    }
    th.coeff-cell>div{
        display: flex;
        align-items: center;
    }
    tr{
        position: relative;
        .coeff-cell{
            max-width: 50px;
            width: 50px;
            svg{
                margin: 0 2px;
            }
        }
        .delete-cell{
            max-width: 40px;
            width: 40px;
            padding: 0;
        }
        .delete-button{
            width: 40px;
            height: 40px;
            padding: 0;
            border-radius: 50%;
        }
    }
    .quantity-container{
        margin: auto;
        .quantity-selector-input{
            width: 60px;
        }
    }

    .lazy-loaded-image span img{
        width: 60px !important;
        height: 60px !important;
        margin: 8px;
    }

}
.complementary-info{
    margin: 2rem;
    .order-card-title{
        text-align: left;
    }
    p{
        font-size: 12px;
        white-space: pre-line;
    }
}

.download-icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -8px;
    margin-right: 8px;
    svg path{
        transition: all 0.2s $FLUID;
    }
    &-bar{
        width: 80%;
        height: 2px;
        background-color: white;
        transition: all 0.2s $FLUID;
    }
}
.confirmed-order-page{
    .confirmed-order-card{
        margin: 1rem auto;
        padding: 1rem 2rem;
        position: relative;
        .download-invoice-button{
            position: absolute;
            right: 2rem;
            top: 2rem;
            &:hover{
                .download-icon{
                    svg path{
                        transform: translateY(3px);
                    }
                    &-bar{
                        width: 100%;
                    }
                }

            }
        }
    }
    .go-to-my-orders-button{
        margin-left: auto;
        svg{
            margin-left: 8px;
            transition: all 0.2s $FLUID;
            transform: translateX(-8px);
        }
        &:hover{
            svg{
                transform: translateX(0);
            }
        }
    }
    .order-confirmed-notice{
        text-align: center;
        font-size: 16px;
        margin: 2rem 0;
        p:first-child{
            margin: 1rem;
        }
        .important{
            text-decoration: underline;
        }
    }
}

.modify-my-order{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    button{
        margin-bottom: 1rem;
    }
    p, span{
        font-size: 12px;
    }
    .attention{
        text-decoration: underline;
    }
}

.MuiInputBase-root.Mui-disabled{
    @include themed($Sedeo-themes) {
        background-color: t('in-process');
    }
    &:hover{
        @include themed($Sedeo-themes) {
            background-color: t('in-process');
        }
    }
}


@media (min-width: $SCREEN_LG) {
    .products-table .lazy-loaded-image span img{
        width: 100px !important;
        height: 100px !important;
        margin: 8px;
    }
}

@media (max-width: $SCREEN_MD) {
    .order-stepper-container{
        padding: 0 2rem;
        .step-container{
            .step{
                .step-info{
                    h4{
                        font-size: 12px;
                    }
                }
                .timeline-circle{
                    width: 18px;
                    height: 18px;
                    &-active-dot{
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }
    .product-image-name{
        font-size: 12px;
    }
    .order-card-container{
        padding: 8px;
        >h2{
            font-size: 14px;
            margin: 8px 0;
        }
        table{
            width: 100%;
            th{
                font-size: 12px;
            }
            td{
                .quantity-container{
                    .quantity-selector{
                        button{
                            width: 18px;
                            height: 18px;
                            padding: 0;
                        }
                        .quantity-selector-input{
                            width: 40px;
                            >div{
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .coefficient-tooltip{
        .tooltip-text-coef{
            font-size: 10px;
        }
    }
}

@media (max-width: $SCREEN_SM) {
    .order-stepper-container{
        padding: 8px 1rem;
        margin-left: -8px;
        margin-right: -8px;
        .step-container{
            .step{
                .step-info{
                    padding: 2px;
                    h4{
                        font-size: 8px;
                    }
                }
                .timeline-circle{
                    width: 14px;
                    height: 14px;
                    margin: 4px;
                    &-active-dot{
                        width: 7px;
                        height: 7px;
                    }
                }
            }
        }
    }
    .order-recap-header{
        .back-button{
            font-size: 12px;
            svg{
                width: 14px;
                height: 14px;
            }
        }
        &-info{
            h4{
                font-size: 10px;
            }
        }
    }
    .product-image-name{
        max-width: unset;
        font-size: 12px;
    }
    .product-image-name{
        font-size: 8px;
        .lazy-loaded-image{
            img{
                width: 40px !important;
                height: 40px !important;
            }
        }
    }
    .order-card-container{
        padding: 8px !important;
        h2{
            font-size: 14px;
            margin: 8px 0;
        }
        .schedule-field {
            >div{
                flex-direction: column;
                align-items: flex-start;
                gap: 0.2rem;
            }
            .field-title{
                width: auto;
                min-width: 130px;
            }
            .shipping-schedule-button{
                min-width: 200px;
                width: 100%;
                padding: 4px 1rem;
                hr{
                    margin: 0.2rem 0.6rem;
                    height: 20px;
                }
            }
        }
        table{
            width: 100%;
            th{
                font-size: 10px;
            }
            
            tr{
                .delete-cell{
                    // display: none;
                    max-width: 20px;
                    width: 20px;
                    .delete-button{
                        display: block;
                        width: 20px;
                        svg{
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
            td{
                font-size: 10px;
                font-weight: 500;
                
                .quantity-container{
                    .quantity-selector{
                        flex-wrap: wrap;
                        // flex-direction: column;
                        button{
                            width: 30px;
                            height: 30px;
                            padding: 0;
                            margin: auto !important;
                        }
                        .quantity-selector-input{
                            //order: 1;
                            max-width: 100%;
                            margin: auto;
                            flex: 0 0 100%;
                            >div{
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
        .pricing-notice, .discount-notice{
            font-size: 10px;
            margin-top: -4px;
            line-height: 1.2;
        }
        .applied-discount{
            font-size: 10px;
        }
        .schedule-field{
            margin: 8px 0;
            height: auto;
            p{
                font-size: 10px;
            }
            button{
                padding: 8px;
                font-size: 10px;
                min-height: 40px;
                &::after{
                    font-size: 22px;
                }
            }
        }
        .handling-selector{
            .field-title{
                font-size: 12px;
            }
            button{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 10px;
                padding: 4px 8px;
                min-width: 70px;
            }
            p{
                margin: 0;
                padding: 0;
            }
            .input-field-notice{
                font-size: 10px;
            }
            .input-field{
                >div{
                    width: fit-content;
                    input{
                        width: auto;
                    }
                }
            }
        }
        .handling-total{
            font-size: 12px;
        }
        .order-validation-notes{
            margin: 0 8px;
            >p{
                font-size: 12px;
            }
            >span{
                font-size: 10px;
            }
            .order-payment-info-bloc{
                margin: 1rem 0;
                p{
                    font-size: 12px;
                }
            }
        }
        .return-contact-phone{
            padding-top: 0;
        }
        .pricing-and-payment{
            .payment-notice, .payment-notice-line{
                margin: 1rem 0;
            }
        }
    }
    .timeslots-list{
        >div{
            max-width: 80%;
        }
        .accordion-container{
            .accordion-menu{
                .Mui-expanded{
                    margin: 12px 0;
                }
            }
            .accordion-menu-title{
                font-size: 12px;
            }
            .accordion-menu-details{
                padding: 8px;
                .accordion-menu-item{
                    min-height: 28px;
                    font-size: 12px;
                }
            }
        }
        .MuiBackdrop-invisible{
            max-width: 100%;
            width: 100%;
        }
        
    }
    .order-confirmed-notice{
        margin: 1rem 0 !important;
        padding: 0 8px !important;
        font-size: 12px !important;
    }
    .complementary-info{
        margin: 1rem 0;
        padding: 0 8px;
    }
    .order-validation-price-info{
        margin: 1rem 0;
        padding: 0 8px;
        hr{
            width: 200px;
        }
    }
    .order-recap-address{
        .right{
            padding: 0;
        }
    }
    .recap-date-hour-info, .delivery-info, .order-recap-address{
        margin: 0;
        padding: 8px;
        h2{
            font-size: 14px;
        }
        p{
            font-size: 12px;
        }
        .delivery-info{
            padding: 0;
        }
    }
    .products-table{
        width: 100%;
        th{
            font-size: 10px;
        }

        tr{
            .delete-cell{
                // display: none;
                max-width: 20px;
                width: 20px;
                .delete-button{
                    display: block;
                    width: 20px;
                    svg{
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
        td{
            font-size: 10px;
            font-weight: 500;

            .quantity-container{
                .quantity-selector{
                    flex-wrap: wrap;
                    // flex-direction: column;
                    button{
                        width: 18px;
                        height: 18px;
                        padding: 0;
                        margin: auto !important;
                    }
                    .quantity-selector-input{
                        //order: 1;
                        max-width: 100%;
                        margin: auto;
                        flex: 0 0 100%;
                        >div{
                            font-size: 10px;
                        }
                    }
                }
            }
        }
        .quantity-cell{
            width: 60px;
        }
    }
    .order-card-subtitle{
        font-size: 14px;
        margin: 1rem 8px;
    }
    .sortby-line{
        span{
            font-size: 12px;
        }
        .sedeo-custom-select{
            margin: 0 0.6rem;
        }
    }
    .sedeo-table{
        .sedeo-table-row{
            td:last-child{
                display: none;
            }
        }
    }
    .orders-row-mobile{
        margin-top: 1rem;
        padding: 8px;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        h4{
            margin: 8px 0;
        }
        .header{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include themed($Sedeo-themes) {
                color: t('lighter-grey');
            }
        }
        .location{
            p{
                line-height: 1.4;
                font-size: 12px;
            }
        }
        .dates{
            margin: 8px 0;
            h4, p{
                line-height: 1.4;
            }
            p{
                font-size: 12px;
            }
            .v-hr{
                margin: auto 0.6rem;
                height: 60px;
            }
        }
        .resume-btn{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            margin-top: 8px;
        }
        .delete-btn{
            width: 100%;
            display: flex;
            justify-content: center;
            button{
                font-size: 12px;
                padding-top: 0;
                @include themed($Sedeo-themes) {
                    color: t('error');
                }
            }
        }
        .product-not-available-for-location-text{
            p{
                line-height: 1.4;
            }
        }
    }
}