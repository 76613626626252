.sedeo-table{
    width: 100% !important;
    .sedeo-table-row{
        margin: 8px 0;
        padding: 8px 1rem;
        display: flex;
        align-items: center;
        text-align: center;
        border-radius: 10px;
        background-color: #f7f7f7;
        th, td{
            flex: 1 1 0;
            margin: 4px;
        }
        .empty-cell{
            flex: 0;
        }
        td{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            position: relative;
            p{
                margin: 0 2px;
                font-size: 12px;
                font-weight: 500;
            }
        }
        .clickable-chip{
            border-radius: 20px;
            padding: 4px 0;
            button{
                transition: transform 0.2s $FLUID;
                &:hover{
                    background-color: inherit;
                }
            }
            &:hover{
                button{
                    transform: translateX(6px);
                }
            }
        }
        .delete-button, .view-button{
            position: absolute;
            top: -18px;
            left: -10px;
        }
    }
    .sedeo-table-row-header{
        cursor: auto;
        @include themed($Sedeo-themes) {
            background-color: t('table-header');
        }
        h5{
            margin: 0 5px;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.5;
            text-align: center;
            @include themed($Sedeo-themes) {
                color: t('lighter-grey');
            }
        }
        .empty-cell{
            flex: 0;
            margin: 0;
            width: fit-content;
        }
    }
    .sedeo-table-row-warning{
        @include themed($Sedeo-themes) {
            background-color: t('sedeo-secondary-color-wheat');
        }
        >td{
            width: 100%;
            // padding: 0 1rem 1rem;
            border-radius: 0 0 10px 10px;
            .product-not-available-for-location-wrapper{
                margin: 0;
                .product-not-available-for-location-text {
                    padding: 8px 1rem;
                    svg{
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }
    .sedeo-table-row-with-warning{
        margin-bottom: -8px;
    }
    .loading-container{
        width: 50px;
        height: 50px;
        >span{
            width: 20px !important;
            height: 20px !important;
        }
    }
}