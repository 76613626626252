@import 'theme';

html {
  scroll-behavior: smooth;
}
html,
body {
  padding: 0;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  //   Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
  scroll-margin-top: 300px;
}

h1,h2,h3,h4,h5,h6,p{
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

main{
  min-height: 80vh;
}

.limited-width{
  max-width: $HOME_VIEWPORT_MAX_WIDTH;
  margin: auto;
}
.g-bg{
  padding: 0 1rem;
  border-radius: 10px;
  @include themed($Sedeo-themes) {
    background-color: t('sedeo-main-color');
    color: t('light-text');
  }
}
.fw600{
  font-weight: 600;
}
.main-page-content{
  position: relative;
}
.page-transition-loading{
  position: fixed !important;
  z-index: 2000 !important;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 3s $FLUID;
  background-color: #e6c0ff;
  span{
    background-color: #8032B3;
  }
  &.show{
    transition: opacity 0s;
    opacity: 1;
  }
}
.flex-start{
  justify-content: flex-start !important;
}
.flex-end{
  justify-content: flex-end !important;
}
.space-between{
  justify-content: space-between !important;
}
.skeleton-loading{
  width: 100%;
}
.Div_container{
  position: relative;
}
.placeholder-image{
  opacity: 0.2;
  img{
    width: 100%;
    height: 100%;
    max-width: 100px;
    max-height: 100px;
  }
}
.sedeo-main-color{
  &-text{
    @include themed($Sedeo-themes) {
      color: t('sedeo-main-color');
    }
  }
}
.error-loading-image-container{
  opacity: 0.2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-placeholder-image + .error-placeholder-image{
  display: none !important;
}
.shadow-mask-left{
  @include themed($Sedeo-themes) {
    background-image: linear-gradient(to left,rgb(255 255 255/0), t('bg-default') 40px);
  }
}
.shadow-mask-right{
  @include themed($Sedeo-themes) {
    background-image: linear-gradient(to right,rgb(255 255 255/0), t('bg-default') 40px);
  }
}
.shadow-mask-bottom{
  @include themed($Sedeo-themes) {
    background-image: linear-gradient(to bottom,rgb(255 255 255/0), t('bg-default') 20px);
  }
}
.highlighter{
  width: 80%;
  height: 2px;
  border-radius: 4px;
  margin: 2px auto 4px;
  @include themed($Sedeo-themes) {
    background-color: t('medium-grey');
  }
}
.warning-text{
  @include themed($Sedeo-themes) {
    color: t('sedeo-orange');
  }
}
.main-borders-margin{
  //max-width: $HOME_VIEWPORT_MAX_WIDTH;
  margin: 0 $MAIN_APP_BORDERS_MARGIN;
}
.sedeo-card{
  border-radius: 20px;
  padding: 1rem;
  margin: 1rem;
}
.lazy-loaded-image{
  width: 100% !important;
  height: 100%;
  position: relative;
  overflow: hidden;
  span{
      // width: 100% !important;
      // height: 100% !important;
      img{
          width: 100% !important;
          height: 100% !important;
          max-width: 350px;
          max-height: 350px;
          object-fit: contain;
      }
  }
  &.loading-bg{
    animation: fadeInOut 2s ease-in-out infinite;
    @include themed($Sedeo-themes) {
      background-color: t('bg-hover');
    }
  }
  .lazy-load-image-loaded{
    width: 100% !important;
    //height: 100% !important;
    height: auto !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}


// FORMS..
.input-field{
  padding: 8px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  flex: 1;
  height: unset;
  line-height: 1.6;
  input{
    border-radius: 20px;
    text-align: center;
    box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.08);
    overflow: hidden;
    padding: 6px 8px;
    @include themed($Sedeo-themes) {
      background-color: t('bg-default');
      border: 1px solid t('lighter-grey');
    }
  }
  textarea{
    border-radius: 20px;
    padding: 8px;
    @include themed($Sedeo-themes) {
      background-color: t('bg-default');
      border: 1px solid t('lighter-grey');
    }
  }
  div, .Mui-disabled:disabled{
    border-radius: 20px;
    background-color: unset;
  }
  div{
    padding: 0;
  }
  label{
    font-size: 18px;
    position: relative;
  }
}
.field-title{
  font-weight: 400;
  font-size: 18px;
}
.MuiPickersDay-dayWithMargin.Mui-selected{
  background-color: #00D2B6 !important;
  @include themed($Sedeo-themes) {
    background-color: t('sedeo-main-color') !important;
  }
}
.event-selector-tooltip-props,.cart-tooltip-props{
  border-radius: 16px;
  box-shadow: 2px 4px 8px 0px #c8c8c8;
}
.event-selector-tooltip-props{
  width: auto !important;
  max-width: 400px;
}
.cart-tooltip-props{
  width: 500px !important;
  max-width: 500px !important;
  max-height: 600px;
  padding: 1rem;
}
.input-field-notice{
  margin: 0;
  margin-top: -4px;
  padding-left: 1rem;
  font-size: 12px;
  line-height: 1.4;
  @include themed($Sedeo-themes) {
    color: t('lighter-grey');
  }
}
.selector-container{
  padding: 8px;
  h4{
    font-weight: 400;
    font-size: 16px;
    margin: 8px 0;
  }
  div{
    display: flex;
    align-items: center;
    width: fit-content;
    h4{
      font-size: 14px;
      margin: 8px;
    }
  }
}

.general-conditions-bloc{
  margin: 8px 0;
  .general-conditions-accept-label{
    font-size: 10px;
    font-style: italic;
    line-height: 1.4;
  }
}

hr{
  @include themed($Sedeo-themes) {
    border-color: t('lighter-grey');
  }
}


@media (max-width: $SCREEN_LG) {
  .main-borders-margin{
    margin: 0 2rem;
  }
}
@media (max-width: $SCREEN_SM) {
  .main-borders-margin{
    margin: 0 8px !important;
  }
  .field-title{
    font-size: 12px;
  }
}

/* This removes the arrows when an input is of type "number", TODO find a way to do it directly in js */
input[type=number] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button{
  -webkit-appearance: none !important;
  margin: 0 !important;
}

