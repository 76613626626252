

.searchbar-container{
  margin-top: 1rem;
  margin-bottom: 1rem;

  .search-box-container{
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    border-radius: 25px;
    box-shadow: 0 0.1px 6px 0.1px rgba(33, 56, 97, 0.3);
    @include themed($Sedeo-themes) {
      color: t('text-dark');
      border: 1px solid t('borders');
    }
  
    .search-input{
      height: 100%;
      padding: 1rem;
      &::placeholder{
        @include themed($Sedeo-themes) {
          color: t('medium-grey');
          font-size: 12px;
        }
      }
    }
    .search-icon{
      height: 30px;
      width: 30px;
      margin: 6px;
      @include themed($Sedeo-themes) {
        background-color: t('sedeo-main-color');
      }
      svg{
        border-radius: 12px;
        @include themed($Sedeo-themes) {
          color: t('light-text');
        }
      }
    }
  }
}

.search-page{


  .loading-container{
    width: 100%;
    span{
      width: 40px !important;
      height: 40px !important;
    }
  }

  .search-page-header{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .search-page-label{
    margin: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 18px;
    padding-right: 18px;
    padding: 24px 18px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    text-transform: none;
    @include themed($Sedeo-themes) {
      color: t('text-dark');
    }
  }

  .product-details-page{
    min-height: 30vh;
  }
}


.search-filter-container{
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-container{
    width: 100%;
    height: fit-content;
    span{
      width: 40px !important;
      height: 40px !important;
    }
  }
}

$dash: 340;

.sedeo-short-logo-loading{
  width: 60px;
  opacity: 0.2;
  stroke-linecap: round;
  stroke-dashoffset: $dash;
  stroke-dasharray: $dash;
  animation: dash 1s $FLUID infinite;
  margin: 2rem auto 0;
  svg{
    width: 100%;
    height: 100%;
  }
}

@media (max-width: $SCREEN_SM) {
  .search-page{
    .search-page-header{
      margin-top: 8px !important;
      margin-bottom: 8px !important;
    }
    .search-page-label{
      font-size: 14px;
      padding: 8px;
    }
    .product-details-page{
      margin: 1rem 8px !important;
    }
  }
}
