.error-page-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin-top: 2rem;
    .error-page-label{
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        text-transform: none;
        margin-top: 2rem;
    }
    .sedeo-button-rounded{
        margin: 2rem 0;
        padding: 8px 40px;
    }
}


@media (max-width: $SCREEN_SM) {
    .error-page-box{
        img{
          width: 100px;
          height: 100px;
        }
        .error-page-label{
          font-size: 14px;
          font-weight: 500;
          margin-top: 1rem;
        }
        .sedeo-button-rounded{
          padding: 6px 30px;
          margin: 1rem 0;
        }
      }
}